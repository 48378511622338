<template>
    <div>
        <button class="btn btn-primary" @click="copyToClipboard"><span class="material-icons">content_copy</span>{{buttonTitle}}</button>
    </div>
</template>

<style scoped>

</style>

<script>
// import * as fb from '../firebase'
import { mapGetters } from 'vuex'
export default {
  name: 'CopyToClipboardButton',
  props: {
    contentToCopy: String,
    buttonTitle: String
  },
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
    })
  },
  created () {

  },
  mounted () {

  },
  methods: {
    fallbackCopyTextToClipboard () {
      var textArea = document.createElement('textarea')
      textArea.value = this.contentToCopy

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    copyToClipboard () {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard()
        return
      }
      navigator.clipboard.writeText(this.contentToCopy).then(function () {
        console.log('Async: Copying to clipboard was successful!')
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    }
  }

}
</script>
