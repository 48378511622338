<template>
  <div class="additem">
    <h1 class="text-light">Item toevoegen</h1>
    <small class="text-danger" v-if="!workingSchool">Kies eerst een school</small>

    <select v-model="chosenSchool" class="form-control form-control-lg mb-2" v-on:change="setWorkingSchool">
      <option v-for="school  in filteredSchools" v-bind:value="school" :key="school">{{ school }}</option>
    </select>

    <form>
      <div class="form-group">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroupFileAddon01"><span class="material-icons">
                photo_library
              </span> UPLOAD</span>
          </div>
          <div class="custom-file">
            <input type="file" accept="image/*" multiple="multiple" v-on:change="previewMultiImage"
              class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" />
            <label class="custom-file-label" for="inputGroupFile01">Kies of neem een foto
            </label>
          </div>
        </div>
        <div v-if="preview_list.length" class="d-flex flex-row flex-wrap justify-content-center border p-2 mt-3">

          <template>
            <div class="card" style="width: 33%" v-for="(item, index) in preview_list" :key="index">
              <img v-bind:src="item" class="card-img-top" alt="..." />
              <div class="card-body">
                <!-- <h5 class="card-title">
                  file name: {{ image_list[index].name }}
                </h5> -->
                <div class="card-text">
                  size: {{ bytesToSize(image_list[index].size) }}
                  <!--    <br>
                  <div class="form-check">
                 <input class="form-check-input" type="radio" name="preview" v-bind:id="'preview' + index" value="true" v-model="image_preview[index]" checked>
                    <label class="form-check-label" v-bind:for="'preview' + index">
                      Als preview instellen
                    </label>

                  </div>
                  <div v-if="image_preview[index]">VOORVERTONING</div>
                  <button class="btn btn-info" v-on:click="updatePreviewImage(index)">Gebruiken als voorvertoning</button>
                  <br>-->

                </div>
              </div>
              <div class="card-footer text-muted">
                Completion:
                {{ image_uploadStatus[index] }} %
                <div class="progress">
                  <div class="progress-bar" v-bind:style="{ width: image_uploadStatus[index] + '%' }" role="progressbar"
                    aria-valuemin="0" aria-valuemax="100"></div>
                </div><br>
                <a href="#" class="btn btn-danger" v-on:click="removePhoto(index)"> <span class="material-icons">
                    delete
                  </span> Verwijderen</a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </form>

    <div class="form-group row text-light">
      <div class="col-sm-8 offset-sm-4">
        <div class="form-check text-left">
          <input class="form-check-input" type="checkbox" id="locatiemeenemen" v-model="locatiemeenemen">
          <label class="form-check-label" for="locatiemeenemen">
            <span class="material-icons">
              my_location
            </span> Huidige locatie meenemen
          </label>
        </div>
      </div>
    </div>

    <button class="btn btn-danger" v-on:click="reset"> <span class="material-icons">
        delete
      </span>Clear All</button>
    <button class="btn btn-success" v-on:click="upload" :disabled="working || !workingInChrome"> <span class="material-icons">
        cloud_upload
      </span> Upload</button>
      <hr>
<div class="text-white">Details van je toestel: <br>{{ debugInfo }}</div>

      <div v-if="!workingInChrome" class="alert alert-danger m-3">Je kan geen toestellen toevoegen als je niet met de Google Chrome browser werkt.
      <br>
        Download Google Chrome gratis op <a href="https://www.google.com/chrome/">https://www.google.com/chrome/</a> om verder te gaan.
    </div>
    <hr />
    <div v-if="uploadcomplete" class="card small">
      <pre>Het uploaden is klaar, zie! Ga naar de toestellen pagina om het item te bekijken.</pre>
    </div>

  </div>

</template>
<style>
.small {
  width: 50%;
}
</style>
<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import * as fb from '../firebase'
import router from '@/router/index'
export default {
  data () {
    return {
      documents: [],
      image: null,
      preview_list: [],
      image_list: [],
      image_uploadStatus: [],
      image_uploadStatusNew: [],
      image_preview: [],
      locatiemeenemen: true,
      globalPosition: null,
      status: '',
      working: false,
      uploadcomplete: false,
      interval: null,
      workingInChrome: false,
      debugInfo: navigator.userAgent
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user',
      devices: 'devices',
      workingSchool: 'workingSchool',
      toastrOptions: 'toastrOptions'
    }),
    filteredSchools () {
      var allschools = this.user.claims.schools
      var filtered = []
      for (var key in allschools) {
        if (allschools[key] === true) {
          filtered.push(key)
        }
      }
      console.log('FILTERED SCHOOLS = ')
      console.log(filtered)
      return filtered
    }
  },
  created () {
    if ((navigator.userAgent.indexOf('CriOS') !== -1)) {
      console.log('Chrome on iOS')
      this.workingInChrome = true
      alert("Als je foto's via de camera wil toevoegen, neem dan eerst de foto's via je camera-app en kom daarna terug naar deze website. Vervolgens kan op deze website via je fotobilbiotheek de genomen foto's toevoegen. Gebruik niet de optie 'Maak foto' om een foto toe te voegen. Doe je dat wel, dan wordt er slechts één foto geuploaded.")
    } else if ((navigator.userAgent.indexOf('Chrome') !== -1) || (navigator.userAgent.indexOf('Firefox') !== -1)) {
      console.log('Chrome')
      this.workingInChrome = true
    } else {
      console.log('Not Chrome')
      this.workingInChrome = false
      alert(
        'Deze website werkt alleen in Google Chrome. Voeg geen toestellen toe via een andere browser. Je kan Google Chrome gratis downloaden op https://www.google.com/chrome/'
      )
    }
    if (this.workingSchool === '') {
      this.chosenSchool = false
    } else {
      this.chosenSchool = this.workingSchool
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('Location OK', position)
        this.globalPosition = position
        // currentlocation.lat = position.coords.latitude
        // currentlocation.long = position.coords.longitude
      })
    } else {
      console.log('Not supported...')
    }
  },
  methods: {
    forceUpdate () {
      // ...
      this.$forceUpdate() // Notice we have to use a $ here
      // ...
    },
    previewMultiImage: function (event) {
      /* this.preview_list = []
      this.image_list = []
      this.image_uploadStatus = []
      this.image_preview = 0 */
      var input = event.target
      var count = input.files.length
      var index = 0
      if (input.files) {
        while (count--) {
          var reader = new FileReader()
          reader.onload = (e) => {
            this.preview_list.push(e.target.result)
          }
          this.image_list.push(input.files[index])
          this.image_uploadStatus[index] = 0
          reader.readAsDataURL(input.files[index])
          index++
        }
        console.log(this.image_preview)
        console.log('Info on image_list')
        console.log(this.image_list)
        this.image_list.forEach((item, index) => {
          console.log('Item %s: %s', index)
          item.name = 'TESTBESTAND.PNG'
        })
        console.log(this.image_list)
      }
    },
    updatePreviewImage: function (index) {
      this.image_preview = index
      console.log('Updated preview image: %s', index)
      console.log(this.image_preview)
    },
    reset: function () {
      this.image = null
      this.image_list = []
      this.preview_list = []
      this.image_uploadStatus = []
      this.image_preview = []
      this.school = ''
    },
    removePhoto: function (index) {
      this.image_list.splice(index, 1)
      this.preview_list.splice(index, 1)
      this.image_uploadStatus.splice(index, 1)
      this.image_preview.splice(index, 1)
    },
    getLongAndLat: async function () {
      return await new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      )
    },
    getLocation: async function () {
      try {
        var position = await this.getLongAndLat()
        this.globalPosition = position
        return position
      } catch (e) {
        console.log('Error: ' + e.message)
      }
    },
    upload: async function () {
      console.log(this.globalPosition)
      console.log('Lengte: ' + this.image_list.length)
      if (!this.workingSchool) {
        this.$toastr.error(
          'Kies eerst een school uit de lijst!',
          'OPGEPAST',
          this.toastrOptions
        )
        return false
      }
      if (this.image_list.length === 0) {
        this.$toastr.error(
          'Kies eerst minstens één foto.',
          'OPGEPAST',
          this.toastrOptions
        )
        return false
      }
      this.working = true
      /*   var previewerFound = false
      this.image_preview.forEach(function (image, previewindex) {
        if (image === true) {
          console.log('previewer found! %s', previewindex)
          this.image_preview[previewindex] = true
          previewerFound = true
        }
      })
      if (!previewerFound) {
        console.log('Geen previewer gevonden...')
        this.image_preview[0] = true
      } */

      const school = this.workingSchool
      var ref = fb.db.collection(this.workingSchool).doc()
      var docId = ref.id
      console.log('Alles wordt weggeschreven in: ' + docId)
      var currentlocation = { lat: null, long: null }
      if (this.locatiemeenemen) {
        if (this.globalPosition) {
          console.log("Using 'this.globalPosition")
          currentlocation.lat = this.globalPosition.coords.latitude
          currentlocation.long = this.globalPosition.coords.longitude
        } else {
          if (navigator.geolocation) {
            console.log('Getting new location...')
            var position = await this.getLocation()
            this.globalPosition = position
            currentlocation.lat = position.coords.latitude
            currentlocation.long = position.coords.longitude
          } else {
            console.log('Not supported...')
          }
        }
      }
      console.log('USER', this.user.data)
      ref.set({
        docId: docId,
        gpsLocation: currentlocation,
        school: this.workingSchool,
        toProcess: true,
        added: new Date().toLocaleString(),
        inbedrijfstelling: new Date().toISOString().split('T')[0],
        maandengarantie: 12,
        currentlyProcessing: true,
        // images: [],
        created: fb.firestoreFieldvalue.serverTimestamp(),
        addedBy_email: this.user.data.email,
        addedBy_userid: this.user.data.uid,
        addedBy_displayname: this.user.data.displayName,
        addedBy_photoUrl: this.user.data.photoURL
      })
      var self = this
      this.interval = setInterval(function () {
        self.checkstate(school, docId)
      }, 100)
      this.image_list.forEach((file, index) => {
        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = fb.storageRef
          .child(this.workingSchool + '/' + docId + '/' + index + '_' + file.name)
          .put(file)

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          fb.fireb.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )

            this.image_uploadStatus[index] = progress
            console.log('Upload ' + index + ' is ' + this.image_uploadStatus[index] + '% done')
            this.forceUpdate()
            switch (snapshot.state) {
              case fb.fireb.storage.TaskState.PAUSED: // or 'paused'
                //   console.log('Upload is paused')
                break
              case fb.fireb.storage.TaskState.RUNNING: // or 'running'
                //   console.log('Upload is running')
                break
            }
          },
          (error) => {
            console.log('Error was thrown!')
            console.log(error)
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break
              case 'storage/canceled':
                // User canceled the upload
                break

                // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL

            //  console.log('Upload ' + index + ' complete... Getting urls...')
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              var newObj = {}
              //      console.log('%s >> %s', index, this.image_preview[index])

              /*  if (this.image_preview[index] === 'undefined') {
                this.image_preview[index] = false
              } */
              newObj = {
                processed: false,
                // preview: this.image_preview[index],
                tags: '',
                dl: downloadURL,
                gs:
                  'gs://' +
                  uploadTask.snapshot.ref.bucket +
                  '/' +
                  uploadTask.snapshot.ref.fullPath
              }
              newObj.preview = false
              if (this.image_preview === index) {
                newObj.preview = true
              }
              console.log('About to write following:', newObj)
              /* ref.update({
                images: fb.firestoreFieldvalue.arrayUnion(newObj)
              }) */
              this.image_uploadStatus[index] = 100
            })
          }
        )
      })
      console.log(this.image_list)
      console.log(this.image_preview)
      // clearInterval(interval)
    },
    checkstate: function (school, docId) {
      var done = true
      this.image_uploadStatus.forEach((item) => {
        if (item !== 100) {
          done = false
        }
      })
      if (done) {
        clearInterval(this.interval)
        console.log('>>>>> STOPPED THE INTERVAL <<<<<<<<<<<<<')
        this.uploadcomplete = true
        console.log('Setting working to false')
        this.working = false
        //  this.reset()
        this.$toastr.warning(
          'Toegevoegd aan de lijst met toestellen! Wacht van zaken in te vullen tot alle foto\'s onderaan het venster verschijnen...',
          'KLAAR',
          this.toastrOptions
        )
        router.push('/toestellen?school=' + school + '&deviceId=' + docId)
      }
      this.status = JSON.stringify(this.image_uploadStatus)
    },
    setWorkingSchool: function () {
      if (this.workingSchool !== this.chosenSchool) {
        this.$store.dispatch('setSavedSchool', this.chosenSchool)
      }
    },
    bytesToSize (bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }
  }
}
</script>
