<template>
    <div>
        <small class="text-danger" v-if="chosenSchools.length == 0"
        >Kies eerst een school</small
      >
      <select
        :multiple="multiple"
        v-model="chosenSchools"
        class="form-control form-control-lg"
        v-on:change="$emit('schools-chosen', chosenSchools)"
      >
        <option
          v-for="school in filteredSchools"
          v-bind:value="school"
          :key="school"
        >
          {{ school }}
        </option>
      </select>
      {{ chosenSchools }}
    </div>
</template>

<style scoped>

</style>

<script>
// import * as fb from '../firebase'
import { mapGetters } from 'vuex'
export default {
  name: 'CopyToClipboardButton',
  props: {
    user: Object,
    multiple: Boolean,
    routedSchool: String
  },
  components: {

  },
  data () {
    return {
      chosenSchools: []
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      workingSchool: 'workingSchool'
    }),
    filteredSchools () {
      var allschools = this.user.claims.schools
      var filtered = []
      for (var key in allschools) {
        if (allschools[key] === true) {
          filtered.push(key)
        }
      }
      console.log('FILTERED SCHOOLS = ')
      console.log(filtered)
      return filtered
    }
  },
  created () {
    console.log('SCHOOL SELECTOR: RoutedSchool:', this.routedSchool)
    if (this.routedSchool) {
      if (this.multiple) {
        this.chosenSchools.push(this.routedSchool)
      } else {
        this.chosenSchools = this.routedSchool
      }
      this.$store.dispatch('setSavedSchool', this.routedSchool)
      this.$emit('schools-chosen', this.routedSchool)
    }
  },
  mounted () {

  },
  methods: {

  }

}
</script>
