<template>
  <div class="home">
    <div class="card mb-2">
      <div class="card-header">
        <h5 class="card-title">Chromebooks</h5>
      </div>
      <div class="card-body">
        <p class="card-text">Gegevens van de Chromebooks uit Google Admin halen en opslaan in deze inventaris</p>
        <button class="btn btn-danger mt-3" v-on:click="startGoogleSync()">
        <span class="material-icons">sync</span> Manually Chromebooks with Google Admin</button>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-header">
        <h5 class="card-title">URLS van de afbeeldingen opnieuw "signen"</h5>
      </div>
      <div class="card-body">
        <p class="card-text">Worden bepaalde afbeeldingen niet meer geladen? De URLS moeten opnieuw "getekend" worden om een geldige URL te zijn. Gebruik hiervoor deze knop.</p>
        <SchoolSelector :user="user" :multiple="false" v-on:schools-chosen="updateSchoolsChosen" />

        <button class="btn btn-danger mt-3" v-on:click="startSigningUrls()">
          <span class="material-icons">photo_library</span> Mannualy Resign Image
          Urls
        </button>

      </div>
    </div>

    <div class="card mb-2">
      <div class="card-header">
        <h5 class="card-title">Exporteren</h5>
      </div>
      <div class="card-body">
        <p class="card-text">Exporteer de gegevens uit deze database naar Google Sheets of CSV.</p>
        <button class="btn btn-danger mt-3" v-on:click="showExport = !showExport">
          <span class="material-icons">import_export</span> Show Export dialog
        </button>
      </div>

      <div class="p-3" v-if="showExport">
      <SchoolSelector :user="user" :multiple="true" v-on:schools-chosen="updateSchoolsChosenAndListDevices" />
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id=""
            >Selecteer alle velden die je wil meenemen in de export.</span
          >
        </div>
        <select
          multiple
          size="10"
          class="custom-select"
          id="inputGroupSelect01"
          v-model="exportFields"
        >
          <option
            v-for="(value, propertyName, index) in exportableFields"
            :key="'exportFields' + index"
            :value="value.field"
          >
            {{ value.forEndUsers }}
          </option>
        </select>
      </div>
      <p class="text-danger w-100 m-2" v-if="exportFields.length === 0">
        Selecteer eerst minstens één veld.
      </p>

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id=""
            >Om toestellen te filteren, typ je op elke nieuwe lijn een
            'zoekopdracht'</span
          >
        </div>
        <textarea
          v-on:change="searchDevices()"
          v-model="searchfilter"
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
        ></textarea>
      </div>

      <div class="alert alert-info">
        {{ filteredDevices.length }} toestellen gevonden voor de scholen
        {{ chosenSchools.join(", ") }}
      </div>

      <a
        class="btn btn-primary m-2"
        :href="exportCurrentList()"
        download="filename.csv"
        v-if="exportFields.length > 0"
        ><span class="material-icons">file_download</span> Download
        CSV-bestand</a
      >
      <button
        class="btn btn-danger m-2"
        v-on:click="exportCurrentListToGoogleSheets()"
        v-if="exportFields.length > 0"
      >
        <span class="material-icons">file_download</span> Opslaan als Google
        Spreadsheet
      </button>
      <div v-if="exportFields.length > 0">
        <div
          class="text-warning w-100 text-wrap"
          v-if="user.googleAccessToken === ''"
        >
          Klik op de rode knop. Je zal worden uitgelogd en opnieuw worden
          ingelogd. Als deze boodschap verdwijnt, klik dan nogmaals op de rode
          knop om de spreadsheet te maken.
        </div>
      </div>
      <a class="btn btn-success" :href="ssUrl" target="_blank" v-if="ssUrl">
        <span class="material-icons"> open_in_new </span> Open hier je
        spreadsheet...</a
      >
    </div>
    </div>

    <div class="card mb-2">
      <div class="card-header">
        <h5 class="card-title">Aanpassingen doen in bulk</h5>
      </div>
      <div class="card-body">
        <p class="card-text">Pas gegevens aan in bulk.</p>
        <button
              class="btn btn-danger mt-3"
              v-on:click="showBulkTools = !showBulkTools"
            >
              <span class="material-icons">edit</span> Show Bulk Tools
            </button>
      </div>

      <div v-if="showBulkTools" class="p-3 w-100 bulkbar">
        <SchoolSelector :user="user" :multiple="true" v-on:schools-chosen="updateSchoolsChosenAndListDevices" />
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id=""
            >Selecteer Toestellen die je in bulk wil aanpassen</span
          >
        </div>
        <select
          multiple
          size="10"
          class="custom-select"
          id="inputGroupSelect01"
          v-model="bulkEditDocs"
        >
          <option
            v-for="(item, index) in filteredDevices"
            :key="'bulkEditDocs' + index"
            :value="item"
          >
            {{ item.title }} - {{ item.merk }} - {{ item.typeserie }}
          </option>
        </select>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id=""
            >Selecteer een veld dat je wil aanpassen...</span
          >
        </div>

        <select
          class="custom-select"
          id="inputGroupSelect01"
          v-model="bulkEditField"
        >
          <option
            v-for="(value, propertyName, index) in bulk_editable_fields"
            :key="'bulkEditField' + index"
            :value="propertyName"
          >
            {{ bulk_editable_fields[propertyName].forEndUsers }}
          </option>
        </select>
        <input type="text" class="form-control" v-model="bulkEditValue" />
      </div>
      <span class="text-warning"
        >Opgepast! Alle gegevens die al in de geselecteerde toestellen ({{
          bulkEditDocs.length
        }}
        stuks) zit, zullen worden overschreven.</span
      >
      <button
        class="btn btn-danger m-2"
        v-on:click="bulkEdit()"
        v-if="bulkEditDocs.length > 0"
      >
        <span class="material-icons">edit_note</span>In bulk aanpassen
      </button>
    </div>
    </div>

    <div class="card mb-2">
      <div class="card-header">
        <h5 class="card-title">Verzekeringen</h5>
      </div>
      <div class="card-body">
        <p class="card-text">Kijk na hoeveel we moeten aangeven voor de verzekeringen. Selecteer eerst de juiste school / scholen in het kader onderaan.</p>
        <ul class="list-group text-left">
          <li class="list-group-item active" aria-current="true">De berekening gaat als zoals hier beschreven:</li>
          <li class="list-group-item"><a href="https://docs.google.com/document/d/1UmIkNs83R8G5xWptQLnxK6J9LIk0elNWltXmtNrmw3k/preview">Uitleg verzekeringen</a></li>

        </ul>
        <button
      class="btn btn-danger mt-3"
      v-on:click="
        showInsurance = !showInsurance;
      "
    >
      <span class="material-icons">edit</span> Toon verzekeringsbedragen
    </button>
    <hr>
    <div class="" v-if="showInsurance">
      <span v-if="chosenSchools.length === 0" class="badge bg-danger text-white m-3">Opgelet! Selecteer eerst enkele scholen hieronder...</span>
      <SchoolSelector :user="user" :multiple="true" v-on:schools-chosen="updateSchoolsChosenAndListDevices" />

      <div class="card bg-info premiesetup">
        <h5>Percentages voor berekening te betalen premie</h5>
        <div class="row">
          <div class="col">
            <label for="itmat-vast-percentage" class="form-label"
              >IT-materiaal VAST</label
            >
          </div>
          <div class="col">
            <input
              v-model="itmatvastpercentage"
              type="number"
              class="form-control"
              id="itmat-vast-percentage"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="itmat-draagbaar-percentage" class="form-label"
              >IT-materiaal DRAAGBAAR</label
            >
          </div>
          <div class="col">
            <input
              v-model="itmatdraagbaarpercentage"
              type="number"
              class="form-control"
              id="itmat-draagbaar-percentage"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label for="elekbureel-vast-percentage" class="form-label"
              >Elek. bureelmateriaal VAST</label
            >
          </div>
          <div class="col">
            <input
              v-model="elekbureelvastpercentage"
              type="number"
              class="form-control"
              id="elekbureel-vast-percentage"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="elekbureel-draagbaar-percentage" class="form-label"
              >Elek. bureelmateriaal DRAAGBAAR</label
            >
          </div>
          <div class="col">
            <input
              v-model="elekbureeldraagbaarpercentage"
              type="number"
              class="form-control"
              id="elekbureel-draagbaar-percentage"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="eleknietbureel-vast-percentage" class="form-label"
              >Elek. NIET bureelmateriaal VAST</label
            >
          </div>
          <div class="col">
            <input
              v-model="eleknietbureelvastpercentage"
              type="number"
              class="form-control"
              id="eleknietbureel-vast-percentage"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="eleknietbureel-draagbaar-percentage" class="form-label"
              >Elek. NIET bureelmateriaal draagbaar</label
            >
          </div>
          <div class="col">
            <input
              v-model="eleknietbureeldraagbaarpercentage"
              type="number"
              class="form-control"
              id="eleknietbureel-draagbaar-percentage"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="exclude-older-than" class="form-label"
              >Toestellen ouder dan ... jaar niet meer verzekeren</label
            >
          </div>
          <div class="col">
            <input
              v-model="maxLeeftijdDevices"
              type="number"
              class="form-control"
              id="exclude-older-than"
            />
          </div>
        </div>
      </div>

            <button class="btn btn-danger m-2" v-on:click="calculateInsurance()">
        Bereken verzekeringspremie!
      </button>

<hr>
      <div
        class="alert alert-warning p-2 m-3"
        v-for="(objcontent, school) in insuranceObj"
        :key="'insurance' + school"
      >

        <h3 class="display-4">{{ school }}</h3>
        <div class="card mb-2">
          <InsuranceDetails :objcontent="objcontent" :categorie="'IT-materiaal'"></InsuranceDetails>
        </div>

        <div class="card mb-2">
          <InsuranceDetails :objcontent="objcontent" :categorie="'Elektrisch bureelmateriaal en audioapparatuur'"></InsuranceDetails>
           </div>

        <div class="card mb-2">
          <InsuranceDetails :objcontent="objcontent" :categorie="'Elektrisch niet-bureelmateriaal'"></InsuranceDetails>
                </div>

        <div class="card">
          <h5 class="font-weight-bold">Geschatte premie TOTAAL</h5>
          <code>€ {{objcontent['TOTAALPREMIE'] }}</code>
        </div>
        <div v-if="objcontent.teOud.length > 0" class="alert alert-danger p-3 m-3">
          <ul class="list-group">
            <li class="list-group-item bg-danger text-white">
              <h3 class="display-4">Toestellen ouder dan {{ maxLeeftijdDevices }} jaar</h3>
              <small class="text-white">Deze toestellen werden niet mee opgenomen in de totaalsommen... Vink "Te verzekeren" af als je niet wil dat deze toestellen nog verzekerd worden. Wil je dat ze wel verzekerd worden? Verander dan de parameters bovenaan om de maximum leeftijd van toestellen aan te passen.</small>
              <CopyToClipboard :buttonTitle="'Kopieer links'" :contentToCopy="oudeToestellenLinks(objcontent.teOud)"></CopyToClipboard>
              <button class="btn btn-warning" @click="markeerAlsNietTeVerzekeren(objcontent.teOud)">Markeer deze toestellen als "Niet te verzekeren"</button>
            </li>
            <li class="list-group-item" v-for="item in objcontent.teOud" :key="item.device.docId">
              <a :href="'https://inventaris.sgfv.be/toestellen?school=' + item.device.school + '&deviceId=' + item.device.docId" target="_blank">{{ item.device.title || 'Naamloos toestel' }} {{ item.device.sgassetid }} - €{{ item.device.prijs }} ({{item.category}}, {{ item.fixed }}) </a>
            </li>
            </ul>
        </div>
      </div>
    </div>
      </div>

    </div>

    <br />

<hr>
<hr>

    <div v-if="bulkEditDocs.length > 0" class="alert alert-secondary">
      <div
        class="card"
        v-for="(item, index) of bulkEditDocs"
        :key="'bulkEditDocs' + index"
      >
        <h6>{{ item.title }}</h6>
        <json-tree :data="item" :level="0"></json-tree>
      </div>
    </div>

    <img alt="logo" class="img-fluid" src="../assets/logo_large.png" />

    <div v-if="user.loggedIn" class="alert alert-success" role="alert">
      You are logged in!
    </div>
    <div v-if="!user.loggedIn" class="alert alert-success" role="alert">
      Login to get started!
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapGetters } from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'
import InsuranceDetails from '@/components/InsuranceDetails.vue'
import CopyToClipboard from '@/components/CopyToClipboardButton.vue'
import SchoolSelector from '@/components/SchoolSelector.vue'
export default {
  components: {
    InsuranceDetails,
    CopyToClipboard,
    SchoolSelector
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user',
      exportableFields: 'exportableFields',
      bulk_editable_fields: 'bulk_editable_fields'
    }),
    filteredSchools () {
      var allschools = this.user.claims.schools
      var filtered = []
      for (var key in allschools) {
        if (allschools[key] === true) {
          filtered.push(key)
        }
      }
      console.log('FILTERED SCHOOLS = ')
      console.log(filtered)
      return filtered
    }
  },
  data () {
    return {
      devices: [],
      filteredDevices: [],
      searchfilter: '',
      deviceIds: [],
      showBulkTools: false,
      bulkEditDocs: [],
      bulkEditField: false,
      bulkEditValue: '',
      showExport: false,
      showInsurance: false,
      exportFields: [],
      schoolToUpdateUrls: false,
      chosenSchools: [],
      insuranceObj: {},
      itmatvastpercentage: 0.21,
      itmatdraagbaarpercentage: 1.25,
      elekbureelvastpercentage: 0.75,
      elekbureeldraagbaarpercentage: 0,
      eleknietbureeldraagbaarpercentage: 0,
      eleknietbureelvastpercentage: 0,
      maxLeeftijdDevices: 3,
      ssUrl: false
    }
  },

  methods: {
    updateSchoolsChosen (schools) {
      this.chosenSchools = schools
      console.log('CHOSEN SCHOOLS = ')
      console.log(this.chosenSchools)
    },
    updateSchoolsChosenAndListDevices (schools) {
      this.chosenSchools = schools
      console.log('CHOSEN SCHOOLS = ')
      console.log(this.chosenSchools)
      this.listDevices()
    },
    startGoogleSync: async function () {
      console.log('Starting Google Sync... Hold on...')
      this.$toastr.info(
        'Gegevens worden uit de cloud opgehaald',
        'Even geduld',
        this.toastrOptions
      )
      var downloadChromeDevicesAndMerge = fb.fireb
        .functions()
        .httpsCallable('downloadChromeDevicesAndMerge')
      try {
        var serverresponse = await downloadChromeDevicesAndMerge()

        this.$toastr.success(
          serverresponse.data.result,
          'Klaar!',
          this.toastrOptions
        )
        console.log(serverresponse.data.result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
      }
    },
    startSigningUrls: async function () {
      console.log('Starting Signing urls... Hold on...')
      this.$toastr.info(
        'Gegevens worden bijgewerkt',
        'Even geduld',
        this.toastrOptions
      )
      var schoolToProcess = ''
      if (typeof this.chosenSchools === 'string') {
        schoolToProcess = this.chosenSchools
      } else {
        schoolToProcess = this.chosenSchools[0]
      }
      console.log('CHOSEN SCHOOLS = ')
      console.log(schoolToProcess)

      var updateSignedUrlsHttps = fb.fireb
        .functions()
        .httpsCallable('updateSignedUrlsHttps')
      try {
        var serverresponse = await updateSignedUrlsHttps({ school: this.choosenSchools[0] })

        this.$toastr.success(
          serverresponse.data.result,
          'Klaar!',
          this.toastrOptions
        )
        console.log(serverresponse.data.result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
      }
    },

    listDevices: async function () {
      this.devices = []
      this.deviceIds = []
      for (var i = 0; i < this.chosenSchools.length; i++) {
        var ref = fb.db.collection(this.chosenSchools[i])
        ref = ref.orderBy('added')
        var newUnsubscriber = ref.onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            var device = doc.data()
            device.docId = doc.id
            if (this.deviceIds.indexOf(doc.id) <= -1) {
              this.devices.push(device)
              this.deviceIds.push(doc.id)
            }
          })
          // console.log(this.devices)
        })
        this.$store.dispatch('addUnsubscriber', newUnsubscriber)
      }
      this.filteredDevices = this.devices
    },

    searchDevices: function () {
      var filterarray = this.searchfilter
        .toLowerCase()
        .split('\n')
        .filter((item) => {
          if (!item) {
            return false
          }
          return true
        })
      // console.log(filterarray)
      if (filterarray.length === 0) {
        this.listDevices()
        return false
      }
      if (this.devices.length !== 0 && filterarray.length !== 0) {
        var foundDevices = []
        this.devices.forEach((device) => {
          var stringed = JSON.stringify(device).toLowerCase()
          var arrOfPreq = []
          arrOfPreq = filterarray.map((item) => {
            return 'not_found'
          })
          for (var i = 0; i < filterarray.length; i++) {
            if (stringed.indexOf(filterarray[i]) > -1) {
              arrOfPreq[i] = true
              // console.log('Gevonden!')
              //      // console.log(device)
            }
          }
          // console.log('FILTER RESULTATEN VOOR ' + device.title)
          // console.log(arrOfPreq)
          if (arrOfPreq.indexOf('not_found') <= -1) {
            // console.log('Thus pushing ' + device.title)
            foundDevices.push(device)
            //   console.log('added filteredDevice')
          }
        })
      }
      this.filteredDevices = [...foundDevices]

      this.$forceUpdate()
    },

    /* BULK EDITS */

    bulkEdit: async function () {
      if (
        this.bulkEditDocs.length === 0 ||
        this.bulkEditField === false ||
        this.bulkEditValue === ''
      ) {
        this.$toastr.warning(
          'Eerst de nodige velden invullen aub!',
          'Jammer',
          this.toastrOptions
        )
      }
      const amount = this.bulkEditDocs.length
      // console.log('VELD DAT WORDT AANGEPAST: ', this.bulkEditField)
      for (const doc of this.bulkEditDocs) {
        //  this.bulkEditDocs.forEach(async (doc, index) => {
        // Enkele mappings doen, zodat de zoekfuncties in firebase werken zoals de andere toestellen:

        if (doc.devicetype === 'chromebook-chromebox') {
          if (this.bulkEditField === 'notes') {
            doc.notes = this.bulkEditValue
            doc.g.notes = this.bulkEditValue
          } else if (this.bulkEditField === 'locatie') {
            doc.locatie = this.bulkEditValue
            doc.g.annotatedLocation = this.bulkEditValue
          } else if (this.bulkEditField === 'sgassetid') {
            doc.sgassetid = this.bulkEditValue
            doc.g.annotatedAssetId = this.bulkEditValue
          } else if (this.bulkEditField === 'orgUnitPath') {
            doc.orgUnitPath = this.bulkEditValue
            doc.g.orgUnitPath = this.bulkEditValue
          }
        }
        if (this.bulkEditValue === 'false') {
          this.bulkEditValue = false
        }
        if (this.bulkEditValue === 'true') {
          this.bulkEditValue = true
        }

        doc[this.bulkEditField] = this.bulkEditValue

        var newDocCaseFolded = await this.$store.dispatch('caseFoldDoc', doc)
        // console.log('CASE FOLDED NEW DOC', newDocCaseFolded)
        // INDIEN EEN CHROMEBOOK >> DEVICE UPDATEN IN ADMIN...
        if (doc.devicetype === 'chromebook-chromebox') {
          var updateChromeDevice =
            fb.functions.httpsCallable('updateChromeDevice')
          try {
            var result = await updateChromeDevice({
              school: newDocCaseFolded.school,
              docId: newDocCaseFolded.docId,
              device: newDocCaseFolded
            })
            console.log('---------SERVER RESP---------------')
            console.log(result)
            this.$toastr.clear()
            this.$toastr.info(
              'Toestel met ID ' + newDocCaseFolded.docId + ' <br> bijgewerkt.',
              'Klaar!',
              this.toastrOptions
            )
          } catch (err) {
            console.log('---------SERVER ERR---------------')
            console.log(err)
            this.$toastr.clear()
            this.$toastr.error(err, 'Fout!', this.toastrOptions)
            return false
          }
        } else {
          // IN ALLE ANDERE GEVALLEN: DOC BIJWERKEN IN FB
          try {
            await fb.db
              .collection(newDocCaseFolded.school)
              .doc(newDocCaseFolded.docId)
              .update(newDocCaseFolded, { merge: true })
            this.$toastr.clear()
            if (newDocCaseFolded.images) {
              await fb.db
                .collection(newDocCaseFolded.school)
                .doc(newDocCaseFolded.docId)
                .update({ images: fb.firestoreFieldvalue.delete() })
            }
            this.$toastr.info(
              'Toestel met ID ' +
                newDocCaseFolded.docId +
                ' <br> bijgewerkt in de database.',
              'Klaar!',
              this.toastrOptions
            )
          } catch (err) {
            console.log('---------FB ERR---------------')
            console.log(err)

            this.$toastr.error(err, 'Fout!', this.toastrOptions)
            return false
          }
        }
        // if (index === this.bulkEditDocs.length) {
        //   this.$toastr.clear()

        // }
      }
      this.$toastr.clear()
      this.$toastr.success(
        'Er werden ' + amount + ' toestellen aangepast!',
        'Klaar!',
        this.toastrOptions
      )
      // })
    },

    /* ALLES EXPORTEREN! */
    exportCurrentList: function (getArray, schoolafkorting) {
      // console.log('Exporting these fields', this.exportFields)
      // console.log('Exporting these documents...', this.filteredDevices)

      // https://stackoverflow.com/questions/17564103/using-javascript-to-download-file-as-a-csv-file/17564369
      var arr = []

      var firstRow = []
      this.exportFields.map((item) => {
        firstRow.push(this.exportableFields[item].forEndUsers)
      })
      firstRow.push('DATABASE-URL')
      arr.push(firstRow)

      this.filteredDevices.map((device) => {
        var deviceRow = []
        if (schoolafkorting && device.school !== schoolafkorting) {
          return false
        }
        this.exportFields.forEach((key) => {
          if (device[key]) {
            deviceRow.push(device[key])
          } else {
            deviceRow.push('')
          }
        })
        deviceRow.push('https://inventaris.sgfv.be/toestellen?school=' + device.school + '&deviceId=' + device.docId)
        arr.push(deviceRow)
      })
      // console.log(arr)

      if (getArray) {
        return arr
      }
      var csv = arr
        .map((v) =>
          v.map((x) => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x)).join(',')
        )
        .join('\n')
      csv = 'data:text/csv;charset=utf-8,' + escape(csv)
      return csv
      // return csv
    },
    /* ALLES EXPORTEREN ALS GSHEET */
    exportCurrentListToGoogleSheets: async function () {
      // console.log('Trying to fix you a Google Sheet')
      // console.log(this.user)
      // console.log('Bearer ' + this.user.googleAccessToken)
      var sheets = this.chosenSchools.map((school) => {
        return { properties: { title: school } }
      })
      try {
        var resultSheetCreation = await fetch(
          'https://sheets.googleapis.com/v4/spreadsheets?key=' +
            'AIzaSyC4oMBZ8aiQzgMXKLIPDZmIQ-HRGuAiQ1o',
          {
            method: 'POST',
            headers: {
              Authorization: 'Bearer ' + this.user.googleAccessToken,
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              properties: {
                title:
                  'INVENTARIS EXPORT - ' +
                  this.chosenSchools.join('|') +
                  ' ' +
                  new Date().toLocaleString()
              },
              sheets: sheets

              /*   [{ properties: { title: 'INVENTARIS' } }
            ] */
            })
          }
        )
        // var jsonresponse = await result// .data()
        // console.log(resultSheetCreation)
        if (resultSheetCreation.status !== 200) {
          this.$toastr.error(
            'Probleem bij het maken van je sheet. Ik log je even uit en terug in. Probeer daarna even opnieuw.',
            'Fout!',
            this.toastrOptions
          )
          this.$store.dispatch('logout')
          this.$store.dispatch('login', { route: '/admintools' })
          router.push('/admintools').catch(() => {})
          return false
        }
        var jsonresponseSheetCreation = await resultSheetCreation.json()
        // console.log(jsonresponseSheetCreation)

        var ssid = jsonresponseSheetCreation.spreadsheetId
        var ssUrl = jsonresponseSheetCreation.spreadsheetUrl
      } catch (err) {
        this.$toastr.error(
          'Probleem bij het maken van je sheet: ' + err,
          'Fout!',
          this.toastrOptions
        )
        return false
      }
      for (var i = 0; i < this.chosenSchools.length; i++) {
        try {
          var resultFillSheet = await fetch(
            'https://sheets.googleapis.com/v4/spreadsheets/' +
              ssid +
              '/values/' +
              escape(this.chosenSchools[i] + '!A:A') +
              ':append?valueInputOption=USER_ENTERED&key=' +
              'AIzaSyC4oMBZ8aiQzgMXKLIPDZmIQ-HRGuAiQ1o',
            {
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + this.user.googleAccessToken,
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                values: this.exportCurrentList(true, this.chosenSchools[i])
              })
            }
          )
          var jsonresponseFillSheet = await resultFillSheet.json()
          console.log(jsonresponseFillSheet)
        } catch (errfill) {
          // console.log(errfill, this.chosenSchools[i])
          this.$toastr.error(
            'De sheet werd gemaakt, maar de gegevens konden niet worden weggeschreven: ' +
              errfill,
            'Fout!',
            this.toastrOptions
          )
          return false
        }
      }
      this.ssUrl = ssUrl
      this.$toastr.success(
        'Sheet werd gemaakt',
        'Gelukt!',
        this.toastrOptionsHardToast
      )

      // .then(result => result.json()).then(data => console.log(data))
      return true
    },
    isThisARecentDevice: function (device) {
      // substract 4 years from the device
      var date = new Date()
      if (!device.inbedrijfstelling) {
        date = new Date(device.created.seconds * 1000 + device.created.nanoseconds / 1000000)
      } else {
        date = new Date(device.inbedrijfstelling)
      }
      var dateXYearsAgo = new Date()
      dateXYearsAgo.setFullYear(dateXYearsAgo.getFullYear() - this.maxLeeftijdDevices)
      if (date > dateXYearsAgo) {
        return true
      }
      return false
    },
    oudeToestellenLinks (arr) {
      console.log('Looking for', arr)
      return arr.map((item) => { return 'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId }).join('\n')
    },
    oudeToestellenIds (arr) {
      console.log('Looking for', arr)
      return arr.map((item) => { return item.docId })
    },
    markeerAlsNietTeVerzekeren (arr) {
      if (window.confirm('Opgelet! Ben je zeker dat je al deze toestellen als niet te verzekeren wil markeren?')) {
        console.log('Ok, let\'s do this')
      } else {
        console.log('Ok, not doing this')
        return false
      }
      var ids = this.oudeToestellenIds(arr)
      ids.forEach((id) => {
        fb.db.collection(arr[0].school).doc(id).update({ teverzekeren: false }, { merge: true })
        console.log('Updated', id, 'in', arr[0].school)
      })
      this.searchDevices()
      this.calculateInsurance()
    },
    calculateInsurance: async function () {
      this.insuranceObj = {}
      this.filteredDevices.map((device) => {
        if (!this.insuranceObj[device.school]) {
          this.insuranceObj[device.school] = {
            'IT-materiaal': { VAST: 0, DEVICESVAST: [], DRAAGBAAR: 0, DEVICESDRAAGBAAR: [] },
            'Elektrisch bureelmateriaal en audioapparatuur': {
              VAST: 0,
              DEVICESVAST: [],
              DRAAGBAAR: 0,
              DEVICESDRAAGBAAR: []
            },
            'Elektrisch niet-bureelmateriaal': { VAST: 0, DEVICESVAST: [], DRAAGBAAR: 0, DEVICESDRAAGBAAR: [] },
            teOud: []
          }
        }

        if (device.defect) {
          return false
        }
        if ((device.teverzekeren !== true) && (device.huurlease !== true)) {
          // Niet te verzekeren en GEEN huur of lease... => skip!
          return false
        }

        var category = ''
        switch (device.devicetype) {
          case 'computer':
            category = 'IT-materiaal'
            break
          case 'chromebook-chromebox':
            category = 'IT-materiaal'
            break
          case 'netwerkapparatuur':
            category = 'IT-materiaal'
            break
          case 'accesspoint':
            category = 'IT-materiaal'
            break
          case 'tablet':
            category = 'IT-materiaal'
            break
          case 'printer':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'digitaalbord':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'beamer':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'scherm':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'audio-video':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'voip':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'huishoudelectro':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'overige':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'gereedschap':
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
            break
          case 'sensoren-detectie':
            category = 'Elektrisch niet-bureelmateriaal'
            break
          default:
            category = 'Elektrisch bureelmateriaal en audioapparatuur'
        }
        var devicesfixed = 'DEVICESVAST'
        var fixed = 'VAST'
        if (!device.vastaanmuur) {
          fixed = 'DRAAGBAAR'
          devicesfixed = 'DEVICESDRAAGBAAR'
        }

        if (this.isThisARecentDevice(device) === false) {
          if (device.huurlease !== true) {
            console.log('%s is too old AND is not rent or lease...', device.title, device)
            this.insuranceObj[device.school].teOud.push({ device: device, category: category, fixed: fixed })
            return false
          } else {
            // Als het wel huur of lease is, verplicht verzekeren.
            device.teverzekeren = true
          }
        }

        // console.log('Gevonden prijs voor %s is %s', device.title, Number(device.prijs))
        this.insuranceObj[device.school][category][fixed] =
          Number(this.insuranceObj[device.school][category][fixed]) +
          Number(device.prijs)
        this.insuranceObj[device.school][category][devicesfixed].push(device)
      })

      for (var key in this.insuranceObj) {
        this.insuranceObj[key]['IT-materiaal']['PREMIE-VAST'] = (Number(this.insuranceObj[key]['IT-materiaal'].VAST) * this.itmatvastpercentage / 100).toFixed(2)
        this.insuranceObj[key]['IT-materiaal']['PREMIE-DRAAGBAAR'] = (Number(this.insuranceObj[key]['IT-materiaal'].DRAAGBAAR) * this.itmatdraagbaarpercentage / 100).toFixed(2)

        this.insuranceObj[key]['Elektrisch bureelmateriaal en audioapparatuur']['PREMIE-VAST'] = (Number(this.insuranceObj[key]['Elektrisch bureelmateriaal en audioapparatuur'].VAST) * this.elekbureelvastpercentage / 100).toFixed(2)
        this.insuranceObj[key]['Elektrisch bureelmateriaal en audioapparatuur']['PREMIE-DRAAGBAAR'] = (Number(this.insuranceObj[key]['Elektrisch bureelmateriaal en audioapparatuur'].DRAAGBAAR) * this.elekbureeldraagbaarpercentage / 100).toFixed(2)

        this.insuranceObj[key]['Elektrisch niet-bureelmateriaal']['PREMIE-VAST'] = (Number(this.insuranceObj[key]['Elektrisch niet-bureelmateriaal'].VAST) * this.eleknietbureelvastpercentage / 100).toFixed(2)
        this.insuranceObj[key]['Elektrisch niet-bureelmateriaal']['PREMIE-DRAAGBAAR'] = (Number(this.insuranceObj[key]['Elektrisch niet-bureelmateriaal'].DRAAGBAAR) * this.eleknietbureeldraagbaarpercentage / 100).toFixed(2)

        this.insuranceObj[key].TOTAALPREMIE = Number(this.insuranceObj[key]['IT-materiaal']['PREMIE-VAST']) +
                                              Number(this.insuranceObj[key]['IT-materiaal']['PREMIE-DRAAGBAAR']) +
                                              Number(this.insuranceObj[key]['Elektrisch bureelmateriaal en audioapparatuur']['PREMIE-VAST']) +
                                              Number(this.insuranceObj[key]['Elektrisch bureelmateriaal en audioapparatuur']['PREMIE-DRAAGBAAR']) +
                                              Number(this.insuranceObj[key]['Elektrisch niet-bureelmateriaal']['PREMIE-VAST']) +
                                              Number(this.insuranceObj[key]['Elektrisch niet-bureelmateriaal']['PREMIE-DRAAGBAAR'])
      }
    }
  }
}
</script>
