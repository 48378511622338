<template>
  <div class="login text-white">
     <img alt="logo" class="img-fluid" src="../assets/logo.png" />

  <div v-if="!user.loggedIn" class="alert alert-info" role="alert">
   Login to get started!

  </div>

    <button class="btn btn-danger" v-if="!user.loggedIn" v-on:click="login()" ><span class="material-icons">face</span> Login</button>

    <hr>
  <div class="text-white">Details van je toestel: <br>{{ debugInfo }}</div>

    <div v-if="user.loggedIn" class="alert alert-success">
      You are successfully logged in as {{ user.data.email }}

    </div>
    <div>
      <button class="btn btn-danger" v-if="user.loggedIn" v-on:click="logout()">Log Out</button>
    </div>
    <hr>
      <br>
    <!--   <pre class="text-white">{{user.claims}}</pre> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
// import router from '@/router/index'
export default {
  data () {
    return {
      debugInfo: navigator.userAgent
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user'
    })
  },

  created () {
    console.log('claims:')
    console.log(this.user.claims)
    // this.logout()
    // this.login()
  },
  methods: {
    login: function () {
      // `this` inside methods points to the Vue instance
      console.log('Loggin in...')
      this.$store.dispatch('login')
      // router.push('/info')
    },
    logout: function () {
      console.log('logging out')
      this.$store.dispatch('logout')
    }
  }
}
</script>
