<template>
  <div >
    <!-- Button trigger modal -->
<button type="button" v-on:click="updateRouterPathToCurrentDevice (device.school, device.docId)" class="btn btn-primary" :id="'modal-button-'+device.school+'-'+device.docId" data-toggle="modal" :data-target="'#modal-edit-'+device.school+'-'+device.docId" :ref="'modal-button-'+device.school+'-'+device.docId">
  <span class="material-icons">
edit
</span> Aanpassen
</button>

<!-- Modal -->
<div class="modal fade text-dark" :id="'modal-edit-'+device.school+'-'+device.docId" data-backdrop="static" data-keyboard="false" tabindex="-1" :aria-labelledby="'device-title-header-' + device.docId" aria-hidden="true">
  <div class="modal-dialog  modal-lg" :ref="'modal-'+device.school+'-'+device.docId">
    <div class="modal-content">
      <div class="modal-header">

         <div class=" modal-title w-100 text-center">
            <h3 class="mb-0">{{device.title}}</h3>

            <div class="mb-0" >
              <div v-if="device.addedBy_displayname" class=" text-muted d-flex flex-row flex-wrap flex-grow-1 justify-content-between align-items-center">
              <div class="small flex-grow-1 text-right pr-1"><i><span class="material-icons">post_add</span>Toegevoegd op {{device.added}} door {{device.addedBy_displayname}}</i></div>

              <img loading="lazy" :src="device.addedBy_photoUrl" height="40px" class="rounded-circle">
              </div>
              <div v-if="device.lastEditAt" class=" text-muted d-flex flex-row flex-wrap flex-grow-1 justify-content-between align-items-center">
              <div class="small flex-grow-1 text-right pr-1"><i><span class="material-icons text-muted">edit</span>Aangepast op {{device.lastEditAt}} door {{device.lastEditBy_displayname}}</i></div>

              <img loading="lazy" :src="device.lastEditBy_photoUrl" height="40px" class="rounded-circle">
              </div>
              <DeviceIcons class="mt-2" :device="device" />
              </div>
          </div>

        <button type="button" class="close" v-on:click="updateRouterPathBackToSchool(device.school)" data-dismiss="modal" aria-label="Close" :ref="'modal-close-button-'+device.school+'-'+device.docId">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body">
<DeviceColorBar :device="device" />
<!-- NAAM -->
        <div class="form-group row">
          <label :for="'device-title_'+ device.docId" class="col-sm-4 col-form-label">Geef een passende naam op</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" :id="'device-title_'+ device.docId" v-model="device.title"  :disabled="device.currentlyProcessing" >
            </div>
        </div>

<!-- SGFV NAAM -->
        <div class="form-group row">
          <label :for="'device-sg-title_'+ device.docId" class="col-sm-4 col-form-label">Geef een SGFV-ID op:</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" :id="'device-sg-title_'+ device.docId" v-model="device.sgassetid"  :disabled="device.currentlyProcessing" >
            </div>
        </div>
<!-- TOESTELTYPE -->
  <div class="form-group row">
        <label  :for="'device-type_'+ device.docId" class="col-sm-4 col-form-label">Toesteltype</label>
        <div class="col-sm-8">
        <select class="custom-select" v-model="device.devicetype" :id="'device-type_'+ device.docId" :disabled="device.currentlyProcessing">
            <option value="computer">COMPUTER</option>
            <option value="chromebook-chromebox" disabled>CHROMEBOOK / CHROMEBOX</option>
            <option value="digitaalbord">DIGITAAL BORD</option>
            <option value="beamer">BEAMER</option>
            <option value="scherm">BEELDSCHERM</option>
            <option value="voip">VOIP TELEFOON</option>
            <option value="audio-video">AUDIO- / VIDEOAPPARATUUR</option>
            <option value="netwerkapparatuur">NETWERKAPPARATUUR</option>
            <option value="accesspoint">ACCESS POINT (WIFIBAKJE)</option>
            <option value="printer">PRINTER MFC</option>
            <option value="huishoudelectro">HUISHOUD-ELECTRO</option>
            <option value="tablet">TABLET / iPad / SMARTPHONE</option>
            <option value="sensoren-detectie">SENSOREN EN DETECTIE</option>
            <option value="gereedschap">GEREEDSCHAP</option>
            <option value="overige">OVERIGE</option>
        </select>

        </div>

        </div>

<!-- LOCATIE -->
   <div class="form-group row">
    <label :for="'device-location-'+ device.docId" class="col-sm-4 col-form-label">Vaste plaats op school</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-location-'+ device.docId" v-model="device.locatie" :disabled="device.currentlyProcessing">
      <small class="text-muted">⚠️ Uitgeleend aan een persoon? <br>Zet dan een vinkje bij "Uitgeleend" en noteer de naam van de ontlener.</small>
    </div>
  </div>

<!-- MERK -->
   <div class="form-group row">
    <label :for="'device-merk-'+ device.docId" class="col-sm-4 col-form-label">Merk</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-merk-'+ device.docId" v-model="device.merk" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- TYPE -->
   <div class="form-group row">
    <label :for="'device-type-serie-'+ device.docId" class="col-sm-4 col-form-label">Type/serie</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-type-serie'+ device.docId" v-model="device.typeserie" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- SERIENUMMER -->
   <div class="form-group row">
    <label :for="'device-type-serienummer-'+ device.docId" class="col-sm-4 col-form-label">Serienummer</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-type-serienummer'+ device.docId" v-model="device.serienummer" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- LEVERANCIER -->
   <div class="form-group row">
    <label :for="'device-type-leverancier-'+ device.docId" class="col-sm-4 col-form-label">Leverancier</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-type-leverancier'+ device.docId" v-model="device.leverancier" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- PRIJS -->
   <div class="form-group row">
    <label :for="'device-prijs-'+ device.docId" class="col-sm-4 col-form-label">Prijs</label>
    <div class="col-sm-8">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
         <span class="input-group-text">€</span>
        </div>
        <input type="number" class="form-control" :id="'device-prijs'+ device.docId" v-model="device.prijs" :disabled="device.currentlyProcessing">
    </div>

    </div>
  </div>

<!-- INBEDRIJFSTELLING -->
   <div class="form-group row">
    <label :for="'device-inbedrijfstelling-'+ device.docId" class="col-sm-4 col-form-label">Datum Inbedrijfstelling</label>
    <div class="col-sm-8">
      <input type="date" class="form-control" :id="'device-inbedrijfstelling-'+ device.docId" v-model="device.inbedrijfstelling" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- UITBEDRIJFSTELLING -->
   <div class="form-group row">
    <label :for="'device-uitbedrijfstelling-'+ device.docId" class="col-sm-4 col-form-label">Datum Uitbedrijfstelling</label>
    <div class="col-sm-8">
      <input type="date" class="form-control" :id="'device-uitbedrijfstelling-'+ device.docId" v-model="device.uitbedrijfstelling" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- AANTAL MAANDEN GARANTIE SINDS INBDERIJFSTELLING -->
   <div class="form-group row">
    <label :for="'device-garantie-'+ device.docId" class="col-sm-4 col-form-label">Aantal maanden garantie</label>
    <div class="col-sm-8">
      <input type="number" min="0" class="form-control" :id="'device-garantie-'+ device.docId" v-model="device.maandengarantie" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- FORMFACTOR -->
  <div v-if="device.devicetype === 'computer'" class="form-group row">
    <label :for="'device-formfactor-'+ device.docId" class="col-sm-4 col-form-label">Formfactor</label>
    <div class="col-sm-8">
        <select :id="'device-formfactor-'+ device.docId" class="custom-select" v-model="device.formfactor" :disabled="device.currentlyProcessing">
          <option value="desktop">DESKTOP</option>
          <option value="laptop">LAPTOP</option>
        </select>
    </div>
  </div>

<!-- OPMERKINGEN -->
   <div class="form-group row">
    <label :for="'device-notes-'+ device.docId" class="col-sm-4 col-form-label">Opmerkingen</label>
    <div class="col-sm-8">
      <textarea class="form-control" :id="'device-notes-'+ device.docId" v-model="device.notes" :disabled="device.currentlyProcessing"></textarea>
    </div>
  </div>

<!-- BLIJFT ALTIJD OP SCHOOL -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-blijftaltijdopschoolcheck-'+ device.docId" v-model="device.vastaanmuur" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-blijftaltijdopschoolcheck-'+ device.docId">
          Blijft altijd op school en gaat nooit mee naar huis
        </label>
      </div>
    </div>
  </div>

<!-- HUUR -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-huurleasecheck-'+ device.docId" v-model="device.huurlease" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-huurleasecheck-'+ device.docId">
          Huur / Lease / Huurkoop
        </label>
      </div>
    </div>
  </div>

<!-- TE VERZEKEREN -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-teverzekerencheck-'+ device.docId" v-model="device.teverzekeren" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-teverzekerencheck-'+ device.docId">
          Te verzekeren
        </label>
      </div>
    </div>
  </div>

<!-- UITGELEEND -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox"  :id="'device-uitgeleendcheck-'+ device.docId" v-model="device.uitgeleend" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-uitgeleendcheck-'+ device.docId">
          Uitgeleend
        </label>
      </div>
    </div>
  </div>
          <!-- UITGELEEND AAN -->
            <div v-if="device.uitgeleend" class="form-group row">
              <label :for="'device-uitgeleendaantext-'+ device.docId" class="col-sm-4 col-form-label">Uitgeleend aan</label>
              <div class="col-sm-8">
                <input type="text" class="form-control"  :id="'device-uitgeleendaantext-'+ device.docId" v-model="device.uitgeleendaan" :disabled="device.currentlyProcessing">
              </div>
            </div>

<!-- DEFECT -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-defectcheck-'+ device.docId" v-model="device.defect" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-defectcheck-'+ device.docId">
          Defect
        </label>
      </div>
    </div>
  </div>
          <!-- DEFECT OPMERKINGEN -->
            <div v-if="device.defect" class="form-group row">
              <label :for="'device-defecttext-'+ device.docId" class="col-sm-4 col-form-label">Defect omschrijving</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" :id="'device-defecttext-'+ device.docId" v-model="device.defectopmerking" :disabled="device.currentlyProcessing">
              </div>
            </div>

<!-- ARBEIDSMIDDEL -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-arbeidsmiddelcheck-'+ device.docId" v-model="device.arbeidsmiddel" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-arbeidsmiddelcheck-'+ device.docId">
          Arbeidsmiddel <small>Gereedschap zoals een grasmachine, boormachine, haagschaar... Belangrijk voor preventie</small>
        </label>
      </div>
    </div>
  </div>

<!-- VOORRAAD -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-voorraadcheck-'+ device.docId" v-model="device.voorraad" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-voorraadcheck-'+ device.docId">
          Voorraad (niet in gebruik)
        </label>
      </div>
    </div>
  </div>

<!-- KRIJGT CHROMEDEVICE NOG UPDATES? -->
   <div v-if="device.devicetype === 'chromebook-chromebox'" class="form-group row">
    <label :for="'device-updatepolicy-'+ device.docId" class="col-sm-4 col-form-label">Organisatie</label>
    <div class="col-sm-8">
      <div class="input-group mb-3">
        <input type="text" class="form-control" :id="'device-updatepolicy'+ device.docId" v-model="device.g.ontvangtNogUpdates" disabled>
    </div>

    </div>
  </div>

<!-- IMAGES -->
<div v-if="device.imagesprocessed && device.imagesprocessed.length > 0" :id="'imageCaroussel-'+device.docId" class="carousel slide mb-2" data-ride="carousel">

  <ol class="carousel-indicators" v-if="device.imagesprocessed && device.imagesprocessed.length > 1" >
    <li v-for="(image, index) in device.imagesprocessed" :key="'device_imagesProcessed_'+index" :data-target="'#imageCaroussel-'+device.docId" :data-slide-to="index" :class="{active : index === 0}" ></li>
  </ol>
  <div class="carousel-inner">
        <div class=" carousel-item " v-for="(image,index) in device.imagesprocessed" :key="'carouselItem_'+index"  :class="{active : index === 0}" style="">
          <div class="card pb-4"  >
            <img loading="lazy" :src="image.dl_thumb" class="card-img-top center-cropped " alt="Foto van het toestel">
            <div class="card-body">
              <div class="card-text">
                  <div>
                      <span v-for="(tag,tagindex) in image.tags" :key="'imageTags_'+tag+'tagIndex_'+tagindex" class="badge badge-pill badge-secondary tags">{{tag}}
                      <span v-on:click="removeTagFromImage(index,tagindex)"  class="badge badge-pill badge-danger pointer">X</span>
                      </span>
                  </div>
                  <div>
                    <textarea class="form-control semitransp mt-1 mb-1" v-model="device.imagesprocessed[index].foundText" rows="4" :disabled="device.currentlyProcessing"></textarea>
                  </div>

              </div>
              <div class="btn-group btn-group-sm mt-2">
                <button class="btn btn-primary" v-on:click="updatepreviewImage(device.docId, device.school, index)" :disabled="device.imagesprocessed[index].preview"> <span class="material-icons">preview</span> Gebruik als preview </button>
                <button class="btn btn-danger" v-on:click="deleteThisImage(device.docId, device.school, index)" :disabled="!device.imagesprocessed[index].gs || device.imagesprocessed.length === 1"> <span class="material-icons">delete</span> Verwijder foto</button>
                <a class="btn btn-info" :href="image.dl_original" target="_blank" v-if="image.dl_original"> <span class="material-icons">open_in_new</span>Origineel</a>
              </div>
            </div>
          </div>
        </div>
  </div>

      <a class="carousel-control-prev" :href="'#imageCaroussel-'+device.docId" role="button" data-slide="prev">
        <span class="-carousel-control-prev-icon" aria-hidden="true"><span class="material-icons large text-dark">navigate_before</span></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" :href="'#imageCaroussel-'+device.docId" role="button" data-slide="next">
        <span class="-carousel-control-next-icon" aria-hidden="true"><span class="material-icons large text-dark">navigate_next</span></span>
        <span class="sr-only">Next</span>
      </a>
</div>

<FileUploader
          :device="device"
          :school="workingSchool"
/>

      <img loading="lazy" v-if="kaartWeergeven && device.gpsLocation && device.gpsLocation.lat && device.gpsLocation.long" :src="getMapsUrl(device.gpsLocation)" class="d-block w-100" alt="...">

 <input type="hidden" :id="'modal-copy-url-'+device.school+'-'+device.docId" :value="generateURL()">
      </div>
      <div class="modal-footer">
         <button v-on:click="removeDevice(device.docId, workingSchool)" class="btn btn-danger m-1"><span class="material-icons">delete_forever</span></button>

        <button class="btn btn-primary" v-on:click="copyUrl()"><span class="material-icons">
link
</span> <span class="material-icons">
content_copy
</span>Kopieer link</button>
        <button :disabled="!device.gpsLocation" class="btn btn-primary" v-on:click="toggleKaart()"><span class="material-icons">
place
</span> Toon kaart</button>
        <button class="btn btn-info" v-on:click="controleerGarantie(device.inbedrijfstelling , device.maandengarantie)"> <span class="material-icons">
receipt
</span> Garantie</button>

        <button
            v-on:click="processImages(device.docId,workingSchool,device)"
            class="btn btn-info"
          > <span class="material-icons">
memory
</span>Analyseer</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="updateRouterPathBackToSchool(device.school)"> <span class="material-icons">
close
</span>Sluiten</button>
        <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="updateDeviceAndClose(device.docId)"><span class="material-icons">
save
</span> Opslaan en sluiten</button>
<button type="button" class="btn btn-primary" v-on:click="updateDevice(device.docId)"><span class="material-icons">
save
</span> Opslaan</button>
      <button class="btn btn-secondary" v-on:click="verhuisWeergeven = !verhuisWeergeven">Apparaat kopieren of verhuizen</button>
<hr>

      </div>

      <div v-if="verhuisWeergeven" class="p-2">
        <span class="text-warning">Kies een 'doelschool'</span>
        <select v-model="doelschool" class="form-control form-control-lg">
      <option v-for="school in filteredSchools" v-bind:value="school" :key="school" >
        {{ school }}
      </option>
    </select>
    <div class="btn-group m-3">
    <button class="btn btn-secondary" @click="verhuisditapparaat()" :disabled="!doelschool"><span class="material-icons">exit_to_app</span>Verhuis dit toestel</button>
     <button class="btn btn-secondary" @click="kopieerditapparaat()" :disabled="!doelschool"><span class="material-icons">content_copy</span>Kopieer dit toestel</button>
     </div>
      </div>

      <button class="btn btn-secondairy" v-on:click="devToolWeergeven = !devToolWeergeven">DevTool weergeven</button>
      <pre v-if="devToolWeergeven">{{device}}</pre>

    </div>
  </div>
</div>
  </div>
</template>
<style scoped>
.carousel-indicators li{
  background-color: #dc3545
}
.modal{padding:25px}
.large{font-size:48px; text-shadow: 0px 0px 8px white;}
.semitransp {    background-color: rgb(255 255 255 / 75%); }
.pointer{cursor:pointer}
.tags{
      color: #212121 !important;
    background-color: rgb(255 255 255 / 75%) !important;
}
.center-cropped{
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    height: 200px;
}
</style>

<script>
// import * as fb from '../firebase'
import { mapGetters } from 'vuex'
import * as fb from '../firebase'
import FileUploader from './FileUploader'
import DeviceColorBar from '@/components/DeviceColorBar.vue'
import DeviceIcons from '@/components/DeviceIcons.vue'
export default {
  name: 'DeviceModal',
  props: {
    msg: String,
    index: Number,
    device: Object,
    school: String
  },
  components: {
    FileUploader,
    DeviceColorBar,
    DeviceIcons
  },
  data () {
    return {
      kaartWeergeven: false,
      devToolWeergeven: false,
      verhuisWeergeven: false,
      doelschool: false
    }
  },
  watch: {
    'device.uitgeleend':
      function (val, oldVal) {
        if (!val && oldVal) {
          this.device.notes = 'Teruggekregen van ' + this.device.uitgeleendaan + ' op ' + new Date().toLocaleDateString() + '\n' + this.device.notes
          this.device.uitgeleendaan = ''
        }
      },
    'device.defect':
    function (val, oldVal) {
      if (!val && oldVal) {
        this.device.notes = 'Hersteld op ' + new Date().toLocaleDateString() + '\n --->' + this.device.defectopmerking + '\n' + this.device.notes
        this.device.defectopmerking = ''
      }
    }

  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user',
      devices: 'devices',
      workingSchool: 'workingSchool',
      activeModalId: 'activeModalId',
      toastrOptions: 'toastrOptions',
      toastrOptionsHardToast: 'toastrOptionsHardToast'
    }),
    filteredSchools () {
      var allschools = this.user.claims.schools
      var filtered = []
      for (var key in allschools) {
        if (allschools[key] === true) {
          filtered.push(key)
        }
      }
      console.log('FILTERED SCHOOLS = ')
      console.log(filtered)
      return filtered
    }
  },
  created () {

  },
  mounted () {
    if (this.activeModalId && (this.activeModalId === this.device.docId)) {
      console.log('MOUNTED Deze moeten we openen! ' + this.device.docId)

      this.$refs['modal-button-' + this.device.school + '-' + this.device.docId].click()
    }
    this.$refs['modal-' + this.device.school + '-' + this.device.docId].removeEventListener('keydown', this.wantToSave)
    this.$refs['modal-' + this.device.school + '-' + this.device.docId].addEventListener('keydown', this.wantToSave)
  },
  beforeDestroy () {
    this.$refs['modal-' + this.device.school + '-' + this.device.docId].removeEventListener('keydown', this.wantToSave)
  },
  /*
    if (this.activeModalId && (this.activeModalId === this.device.docId)) {
      console.log('MOUNTED Deze moeten we openen! ' + this.device.docId)
      //   console.log('faking click on ' + 'modal-button-' + this.device.school + '-' + this.device.docId)
      this.$refs['modal-button-' + this.device.school + '-' + this.device.docId].click()

    // document.getElementById('modal-button-' + this.device.school + '-' + this.device.docId).click()
    } else { console.log('Skipping. Not my thing...') }
  },
  updated () {
    if (this.activeModalId && (this.activeModalId === this.device.docId)) {
      console.log('UPDATED Deze moeten we openen! ' + this.device.docId)
      //   console.log('faking click on ' + 'modal-button-' + this.device.school + '-' + this.device.docId)
      this.$refs['modal-button-' + this.device.school + '-' + this.device.docId].click()

    // document.getElementById('modal-button-' + this.device.school + '-' + this.device.docId).click()
    }
  }, */
  methods: {
    getMapsUrl: function (gpsLocation) {
      var url = 'https://maps.googleapis.com/maps/api/staticmap?center=' + gpsLocation.lat + ',' + gpsLocation.long + '&' +
                  'zoom=18&' +
                  'size=500x400&' +
                  'scale=2&' +
                  'maptype=roadmap&' +
                  'markers=color:red|' + gpsLocation.lat + ',' + gpsLocation.long + '&' +
                  'key=AIzaSyC4oMBZ8aiQzgMXKLIPDZmIQ-HRGuAiQ1o'
      return url
    },
    updateDeviceAndClose: async function (docId) {
      console.log('Updating document...')
      this.$toastr.info('Bezig met opslaan...', 'Even geduld...', this.toastrOptions)

      var caseFoldedDeviceDoc = await this.$store.dispatch('caseFoldDoc', this.device)

      await fb.db.collection(this.workingSchool).doc(docId).update(caseFoldedDeviceDoc, { merge: true })
      if (this.device.images) {
        await fb.db.collection(this.workingSchool).doc(docId).update({ images: fb.firestoreFieldvalue.delete() })
      }
      this.updateRouterPathBackToSchool(this.workingSchool)
      this.$toastr.clear()
      this.$toastr.success('Klaar met opslaan van device met ID: <br>' + docId, 'Goed, zo!', this.toastrOptions)
    },
    updateDevice: async function (docId) {
      console.log('Updating document...')
      this.$toastr.info('Bezig met opslaan...', 'Even geduld...', this.toastrOptions)

      var caseFoldedDeviceDoc = await this.$store.dispatch('caseFoldDoc', this.device)

      await fb.db.collection(this.workingSchool).doc(docId).update(caseFoldedDeviceDoc, { merge: true })
      if (this.device.images) {
        await fb.db.collection(this.workingSchool).doc(docId).update({ images: fb.firestoreFieldvalue.delete() })
      }
      this.$toastr.clear()

      this.$toastr.success('Klaar met opslaan van device met ID: <br>' + docId, 'Goed, zo!', this.toastrOptions)
    },
    removeTagFromImage: function (imageindex, tagindex) {
      console.log('removing %s from image %s...', tagindex, imageindex)
      this.device.imagesprocessed[imageindex].tags.splice(tagindex, 1)
    },
    processImages: async function (docId, school, device) {
      this.$toastr.info('Bezig met analyseren...', 'Even geduld...', this.toastrOptions)
      var caseFoldedDeviceDoc = await this.$store.dispatch('caseFoldDoc', this.device)
      caseFoldedDeviceDoc.currentlyProcessing = true
      await fb.db.collection(this.workingSchool).doc(docId).update(caseFoldedDeviceDoc, { merge: true })
      console.log('Calling process images ', docId, school)
      var processImages = fb.functions.httpsCallable('processImages')
      try {
        var result = await processImages({ school: school, docId: docId, devicetype: device.devicetype })
        this.$toastr.info(result.data.result, 'Klaar!', this.toastrOptions)
        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
      }
    },
    controleerGarantie: async function (inbedrijfstelling, maandengarantie) {
      var nogInGarantie = await this.$store.dispatch('controleerGarantie', { inbedrijfstelling: inbedrijfstelling, maandengarantie: maandengarantie })
      console.log('Nog in garantie: %s', nogInGarantie)
      if (nogInGarantie === 'insufficient_data') {
        this.$toastr.warning('Onvoldoende gegevens om dit te berekenen...', 'Hmm...', this.toastrOptions)
        return false
      }
      if (nogInGarantie) {
        this.$toastr.success('Nog in garantie!', 'Goed nieuws', this.toastrOptions)
      } else {
        this.$toastr.warning('Spijtig... Geen garantie meer', 'Jammer', this.toastrOptions)
      }
      return true
    },
    updatepreviewImage: async function (docId, school, imageindex) {
      console.log('school: %s, docId: %s', school, docId)
      console.log('New preview image is number: %s', imageindex)
      this.device.imagesprocessed.forEach((image, index) => {
        if (index === imageindex) {
          console.log('New previewer!')
          this.device.imagesprocessed[index].preview = true
        } else {
          this.device.imagesprocessed[index].preview = false
        }
      })
      await fb.db.collection(this.workingSchool).doc(docId).update(this.device, { merge: true })
    },
    deleteThisImage: async function (docId, school, index) {
      console.log('deleting this image...', docId, school, index)
      var obj = { docId: docId, school: school, imageIndex: index }
      this.$store.dispatch('removeImageFromDocument', obj)
    },
    toggleKaart: function () {
      this.kaartWeergeven = !this.kaartWeergeven
    },
    generateURL () {
      var fullUrl = window.location.origin + this.$route.path + '?school=' + this.device.school + '&deviceId=' + this.device.docId

      return fullUrl
    },
    copyUrl () {
      const testingCodeToCopy = document.querySelector('#modal-copy-url-' + this.device.school + '-' + this.device.docId)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        document.execCommand('copy')

        this.$toastr.success('Link gekopieerd', 'Klaar', this.toastrOptions)
      } catch (err) {
        this.$toastr.warning('Er ging iets fout: ' + err, 'Probleem', this.toastrOptions)
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    removeDevice: function (key, school) {
      var obj = { school: school, docid: key }
      console.log('Removing ', JSON.stringify(obj), school)
      this.$store.dispatch('removeData', obj)
      this.$refs['modal-close-button-' + this.device.school + '-' + this.device.docId].click()
      this.updateRouterPathBackToSchool(school)
    },
    updateRouterPathBackToSchool (school) {
      console.log('back to school...')
      this.$store.dispatch('setActiveModalId', false)
      this.$router.push('/toestellen?school=' + school)
    },
    updateRouterPathToCurrentDevice (school, docId) {
      this.$router.push('/toestellen?school=' + school + '&deviceId=' + docId)
    },
    async verhuisditapparaat () {
      console.log('Moving device %s to %s', this.device.docId, this.doelschool)
      // Probeer te kopiëren.
      var moveDocumentToNewSchool = fb.functions.httpsCallable('moveDocumentToNewSchool')
      try {
        this.$refs['modal-close-button-' + this.device.school + '-' + this.device.docId].click()
        var result = await moveDocumentToNewSchool({ oldSchool: this.workingSchool, oldDocId: this.device.docId, newSchool: this.doelschool })

        this.$store.dispatch('setActiveModalId', result.data.newDocId)
        this.$router.push('/toestellen?school=' + result.data.newSchool + '&deviceId=' + result.data.newDocId)
        var fullUrl = `https://inventaris.sgfv.be/toestellen?school=${result.data.newSchool}&deviceId=${result.data.newDocId}`
        this.$toastr.info(`Klik <a href="${fullUrl}" target="_blank">hier</a> om de toestelfiche te openen.`, 'Klaar!', this.toastrOptionsHardToast)

        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
        console.log(err)
      }
    },
    async kopieerditapparaat () {
      console.log('Copying device %s to %s', this.device.docId, this.doelschool)
      // Probeer te kopiëren.
      var copyDocumentToNewSchool = fb.functions.httpsCallable('copyDocumentToNewSchool')
      try {
        this.$refs['modal-close-button-' + this.device.school + '-' + this.device.docId].click()
        var result = await copyDocumentToNewSchool({ oldSchool: this.workingSchool, oldDocId: this.device.docId, newSchool: this.doelschool })

        this.$store.dispatch('setActiveModalId', result.data.newDocId)
        this.$router.push('/toestellen?school=' + result.data.newSchool + '&deviceId=' + result.data.newDocId)
        var fullUrl = `https://inventaris.sgfv.be/toestellen?school=${result.data.newSchool}&deviceId=${result.data.newDocId}`
        this.$toastr.info(`Klik <a href="${fullUrl}" target="">hier</a> om de toestelfiche te openen.`, 'Klaar!', this.toastrOptionsHardToast)

        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
        console.log(err)
      }
    },
    wantToSave (e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return
      }
      e.preventDefault()
      console.log('saving from shortcut!')
      this.updateDevice(this.device.docId)
    }

  }

}
</script>
