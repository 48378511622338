<template>
  <div class="listeddevices p-1" v-bind:class="{ 'filteredview': filteredVersion }">
    <div class="alert alert-primary">
      <div class="input-group">
        <div class="input-group">
          <div class="input-group-prepend">

            <span class="input-group-text bg-primary text-white" id="">Snel zoeken op SGFV-ID 🔎</span>
          </div>

          <input type="text" class="form-control" v-model="quickSearch">

        </div>

      </div>
    </div>
    <h1 class="text-light display-3">Toestellen
      <span>
        <button class="btn btn-secondary text-white" v-if="!toggleView"
          v-on:click="toggleView = !toggleView; activeModalId = ''"><span class="material-icons">view_list</span></button>
        <button class="btn btn-secondary text-white" v-if="toggleView"
          v-on:click="toggleView = !toggleView; activeModalId = ''"><span class="material-icons">grid_view</span></button>
      </span>
    </h1>

    <span class="text-success" v-if="devices.length > 0">{{ devices.length }} stuk(s)</span>
    <div v-if="busy && chosenSchool !== false" class="text-white">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>Gegevens aan het ophalen!
    </div>
    <SchoolSelector :routedSchool="chosenSchool" :user="user" :multiple="false"
      v-on:schools-chosen="updateSchoolsChosenAndListDevices" :key="updateTheChosenSchoolPlease" />

    <button class="btn btn-info m-2"
      v-on:click="showFilters = !showFilters; showBulkTools = false; showExport = false"><span
        class="material-icons">search</span> Zoeken naar...<span v-if="showFilters"
        class="material-icons">expand_less</span><span v-else class="material-icons">expand_more</span></button>
    <button class="btn btn-info m-2" v-on:click="showExport = !showExport; showBulkTools = false; showFilters = false">
      Exporteer... <span v-if="showExport" class="material-icons">expand_less</span><span v-else
        class="material-icons">expand_more</span> </button>
    <button class="btn btn-info m-2" v-on:click="showBulkTools = !showBulkTools; showExport = false; showFilters = false">
      Bulk edit... <span v-if="showExport" class="material-icons">expand_less</span><span v-else
        class="material-icons">expand_more</span> </button>

    <div v-if="showFilters" class="w-100 p-3 filterbar  ">

      <div class="btn-group flex-wrap mb-1" role="group" aria-label="Snelle filters">
        <div class="btn-group" role="group">
          <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            Chromebooks
          </button>
          <div class="dropdown-menu">
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ devicetypefilter: 'chromebook-chromebox' })">Chromebooks</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ devicetypefilter: 'chromebook-chromebox', defectfilter: 'true' })">Defecte
              Chromebooks</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ devicetypefilter: 'chromebook-chromebox', defectfilter: 'false' })">Werkende
              Chromebooks</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ devicetypefilter: 'chromebook-chromebox', doelgroeptoestelfilter: 'leerlingen' })">Chromebooks
              Leerlingen</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ devicetypefilter: 'chromebook-chromebox', doelgroeptoestelfilter: 'personeel' })">Chromebooks
              Personeel</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ devicetypefilter: 'chromebook-chromebox', defectfilter: 'false', uitgeleendfilter: 'false' })">Werkende,
              niet uitgeleende Chromebooks</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ devicetypefilter: 'chromebook-chromebox', uitgeleendfilter: 'true' })">Uitgeleende
              Chromebooks</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ krijgtnogupdatesfilter: 'true' })">Chromebooks die nog updates krijgen</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ krijgtnogupdatesfilter: 'false' })">Chromebooks die geen updates meer krijgen</button>
            <button type="button" class="dropdown-item btn btn-secondary"
              @click="quickFilter({ diskusageFilter: 80 })">Chromebooks waarvan de schijf volloopt</button>
          </div>
        </div>
        <button type="button" class="btn btn-secondary" @click="quickFilter({ defectfilter: 'true' })">Defecte
          toestellen</button>
        <button type="button" class="btn btn-secondary" @click="quickFilter({ teverzekerenfilter: 'true' })">Te verzekeren
          toestellen</button>
        <button type="button" class=" btn btn-secondary" @click="quickFilter({ orderByFilter: 'added' })">Sorteren op
          laatst toegevoegd</button>

      </div>

      <div class="input-group">
        <div class="input-group">
          <div class="input-group-prepend">

            <span class="input-group-text" id="">Zoek op een stuk tekst</span>
          </div>

          <input type="text" class="form-control" v-model="anyTextFilter">

        </div>
        <div class="input-group-prepend">

          <span class="input-group-text" id="">Zoek op toesteltype</span>
        </div>

        <select class="custom-select" id="inputGroupSelect01" v-model="devicetypefilter">
          <option value="null">Toon alles</option>
          <option value="computer">COMPUTER</option>
          <option value="chromebook-chromebox">CHROMEBOOK / CHROMEBOX</option>
          <option value="digitaalbord">DIGITAAL BORD</option>
          <option value="beamer">BEAMER</option>
          <option value="scherm">BEELDSCHERM</option>
          <option value="audio-video">AUDIO- / VIDEOAPPARATUUR</option>
          <option value="voip">VOIP TELEFOON</option>
          <option value="netwerkapparatuur">NETWERKAPPARATUUR</option>
          <option value="accesspoint">ACCESS POINT (WIFIBAKJE)</option>
          <option value="printer">PRINTER MFC</option>
          <option value="huishoudelectro">HUISHOUD-ELECTRO</option>
          <option value="tablet">TABLET / iPad / SMARTPHONE</option>
          <option value="sensoren-detectie">SENSOREN EN DETECTIE</option>
          <option value="gereedschap">GEREEDSCHAP</option>
          <option value="overige">OVERIGE</option>
        </select>

      </div>

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Zoek op teverzekeren</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="teverzekerenfilter">
          <option value="null">Toon alles</option>
          <option value="true">Te verzekeren</option>
          <option value="false">Niet te verzekeren</option>

        </select>

      </div>

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Zoek op doelgroep</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="doelgroeptoestelfilter">
          <option value="null">Toon alles</option>
          <option value="personeel">Personeel</option>
          <option value="leerlingen">Leerlingen</option>

        </select>

      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Zoek op vastaanmuur</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="vastaanmuurfilter">
          <option value="null">Toon alles</option>
          <option value="true">Vast aan muur</option>
          <option value="false">Niet vast aan muur</option>

        </select>

      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Zoek op defect</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="defectfilter">
          <option value="null">Toon alles</option>
          <option value="true">Defect</option>
          <option value="false">Niet defect</option>

        </select>

      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Zoek op uitgeleend</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="uitgeleendfilter">
          <option value="null">Toon alles</option>
          <option value="true">Uitgeleend</option>
          <option value="false">Niet Uitgeleend</option>

        </select>

      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Zoek op voorraad</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="voorraadfilter">
          <option value="null">Toon alles</option>
          <option value="true">Voorraad</option>
          <option value="false">Geen voorraad</option>

        </select>

      </div>

      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Zoek op arbeidsmiddelen</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="arbeidsmiddelfilter">
          <option value="null">Toon alles</option>
          <option value="true">Arbeidsmiddel</option>
          <option value="false">Geen arbeidsmiddel</option>

        </select>

      </div>
      <div class="input-group">
        <div class="input-group-prepend">

          <span class="input-group-text" id="">Sorteren op...</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="orderByFilter">

          <option value="title">Titel</option>
          <option value="sgassetid">SGFV-ID</option>
          <option value="prijs">PRIJS</option>
          <option value="typeserie">Type/Serie</option>
          <option value="devicetype">Soort toestel</option>
          <option value="added">Toegevoegd (added)</option>
          <option value="created">Toegevoegd (created)</option>
        </select>
      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Zoek op 'Ontvangt nog updates'</span>
        </div>
        <select class="custom-select" id="inputGroupSelect01" v-model="krijgtnogupdatesfilter">
          <option value="null">Toon alles</option>
          <option value="true">Ontvangt nog updates</option>
          <option value="false">Ontvangt geen updates meer</option>

        </select>

      </div>

      <div class="input-group">
        <div class="input-group-prepend">

          <span class="input-group-text" id="">Zoek een recente gebruiker (Chromebooks only)</span>
        </div>

        <input type="text" class="form-control" v-model="recentUserFilter">

      </div>

      <div class="input-group">
        <div class="input-group-prepend">

          <span class="input-group-text" id="">Toon Chromebooks met schijfgebruik van meer dan... %</span>
        </div>

        <input type="number" class="form-control" v-model="diskusageFilter">

      </div>
      <div class="filterbuttons m-2">
        <button class="btn btn-warning m-2" v-on:click="listDevices(true, false)" :ref="'filterButton'">Gegevens
          filteren</button>
        <button class="btn btn-warning m-2" v-on:click="clearFilters(true)">Filter wissen</button>

      </div>

    </div>
    <div v-if="showExport" class="w-100 m-2 exportbar">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Selecteer alle velden die je wil meenemen in de export.</span>
        </div>
        <select multiple size="10" class="custom-select" id="inputGroupSelect01" v-model="exportFields">
          <option v-for="(value, propertyName, index) in exportableFields" :key="'exportFields' + index"
            :value="value.field">{{ value.forEndUsers }}</option>

        </select>

      </div>
      <p class="text-danger w-100 m-2" v-if="exportFields.length === 0">Selecteer eerst minstens één veld.</p>

      <a class="btn btn-primary m-2" :href="exportCurrentList()" download="filename.csv"
        v-if="exportFields.length > 0"><span class="material-icons">file_download</span> Download CSV-bestand</a>
      <button class="btn btn-danger m-2" v-on:click="exportCurrentListToGoogleSheets()"
        v-if="exportFields.length > 0"><span class="material-icons">file_download</span> Opslaan als Google
        Spreadsheet</button>
      <div v-if="exportFields.length > 0">
        <div class="text-warning w-100 text-wrap" v-if="user.googleAccessToken === ''">
          Klik op de rode knop. Je zal worden uitgelogd en opnieuw worden ingelogd. Als deze boodschap verdwijnt, klik dan
          nogmaals op de rode knop om de spreadsheet te maken.
        </div>
      </div>
      <a class="btn btn-success" :href="ssUrl" target="_blank" v-if="ssUrl"> <span class="material-icons">
          open_in_new
        </span> Open hier je spreadsheet...</a>

    </div>
    <span v-if="filteredVersion" class="material-icons text-white">filter_alt</span>

    <div v-if="showBulkTools" class="w-100 p-3 bulkbar  ">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Selecteer Toestellen die je in bulk wil aanpassen</span>
        </div>
        <select multiple size="10" class="custom-select" id="inputGroupSelect01" v-model="bulkEditDocs">
          <option v-for="(item, index) in devices" :key="'bulkEditDocs' + index" :value="item">{{ item.title }} -
            {{ item.merk }} - {{ item.typeserie }}</option>

        </select>

      </div>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Selecteer een veld dat je wil aanpassen...</span>
        </div>

        <select class="custom-select" id="inputGroupSelect01" v-model="bulkEditField">

          <option v-for="(value, propertyName, index) in bulk_editable_fields" :key="'bulkEditField' + index"
            :value="propertyName">{{ bulk_editable_fields[propertyName].forEndUsers }}</option>

        </select>
        <input type="text" class="form-control" v-model="bulkEditValue">

      </div>
      <span class="text-warning">Opgepast! Alle gegevens die al in de geselecteerde toestellen ({{ bulkEditDocs.length }}
        stuks) zit, zullen worden overschreven.</span>
      <button class="btn btn-danger m-2" v-on:click="bulkEdit()" v-if="bulkEditDocs.length > 0"><span
          class="material-icons">edit_note</span>In bulk aanpassen</button>

    </div>
    <div v-if="devices.length === 0 && busy == false" class="text-white"><span class="material-icons">
        sentiment_very_dissatisfied
      </span> Niets gevonden...</div>

    <!-- LIJST WEERGAVE -->
    <div v-if="toggleView">
      <button v-on:click="toggleListViewFields = !toggleListViewFields" class="btn btn-info mb-1">Kies kolommen<span
          v-if="toggleListViewFields" class="material-icons">expand_less</span><span v-else
          class="material-icons">expand_more</span> </button>
      <div class="input-group mb-2" v-if="toggleListViewFields">
        <div class="input-group-prepend">
          <span class="input-group-text" id="">Selecteer 3 of 4 velden die je wilt weergeven</span>
        </div>
        <select multiple size="10" class="custom-select" id="inputGroupSelect01" v-model="listViewFields">
          <option v-for="(value, propertyName, index) in exportableFields" :key="'listViewFields' + index"
            :value="value.field">{{ value.forEndUsers }}</option>

        </select>

      </div>
      <ul class="list-group">
        <li class="list-group-item mb-2 rounded shadow-sm" v-for="(device, index) in devices"
          :key="'device' + device.docId">

          <div class="media">
            <div class="d-flex flex-wrap flex-column justify-content-around">
              <img class=" align-self-center rounded" loading="lazy" style=" object-fit: contain;" height="70" width="70"
                :src="previewImageOnly(device['imagesprocessed'])" alt="Voorbeeldafbeelding van toestel">
              <DeviceIcons :device="device" />
            </div>
            <div class="media-body">

              <div class="col-container d-flex flex-nowrap flex-row align-items-center justify-content-between">

                <div class="align-left d-flex flex-wrap flex-row flex-grow-1 align-content-stretch ">
                  <DeviceColorBar :device="device" />
                  <div v-for="item in listViewFields" :key="'listViewFieldsItem' + item"
                    class="d-flex flex-wrap flex-row justify-content-between border-right w-25 h-100">
                    <div
                      class="flex-fill align-self-center align-content-stretch justify-content-between text-break pl-1 pr-1 "
                      v-if="device[item]"><b>{{ exportableFields[item].forEndUsers }}</b>:<br> {{ device[item] }}</div>
                    <div class="flex-fill align-self-center align-content-stretch justify-content-between text-break"
                      v-else><b>{{ exportableFields[item].forEndUsers }}</b>:<br> - </div>
                  </div>
                </div>
                <div class="align-right">
                  <button v-on:click="controleerGarantie(device.inbedrijfstelling, device.maandengarantie)"
                    class="btn btn-info m-1"><span class="material-icons">receipt</span> Garantie</button>
                  <button v-if="!device.processed" v-on:click="processImages(device.docId, workingSchool, device)"
                    class="btn btn-info m-1" :disabled="device.currentlyProcessing || device.processed"><span
                      class="material-icons">memory</span></button>
                  <button v-if="device.processed" v-on:click="processImages(device.docId, workingSchool, device)"
                    class="btn btn-info m-1" :disabled="device.currentlyProcessing || device.processed"><span
                      class="material-icons">memory</span></button>
                  <DeviceModal v-if="device.devicetype !== 'chromebook-chromebox'" :device="device" :index="index"
                    :school="workingSchool" :activeModalId="activeModalId" />

                  <ChromeDeviceModal v-if="device.devicetype === 'chromebook-chromebox'" :device="device" :index="index"
                    :school="workingSchool" :activeModalId="activeModalId" />
                </div>
              </div>
            </div>
          </div>

        </li>

      </ul>
    </div>
    <!-- RASTER WEERGAVE -->
    <div v-if="!toggleView" class="cardcontainer d-flex flex-row flex-wrap justify-content-center ">

      <div class="card m-1 zoomcard" v-for="(device, index) in devices" :key="'zoomcard' + device.docId"
        style="width: 18rem;">
        <img class="card-img-top" loading="lazy" style=" object-fit: contain;" height="120"
          :src="previewImageOnly(device['imagesprocessed'])" alt="Voorbeeldafbeelding van toestel">
        <div class="card-body">
          <h5 class="card-title">

            <span v-if="device.title">{{ device.title }}</span>
            <span v-else-if="device.devicetype === 'chromebook-chromebox'">Chrome-device</span>
            <span v-else>Naamloos toestel</span>
          </h5>
          <p class="card-text"><i v-if="device.sgassetid">{{ device.sgassetid }}</i> <i v-else>-</i></p>
          <p class="card-text">
            <span v-if="device.typeserie">{{ device.typeserie }} </span>
            <span v-else>Naamloos type </span>

            <DeviceIcons :device="device" />
          </p>

        </div>
        <div class="card-footer">
          <button v-on:click="controleerGarantie(device.inbedrijfstelling, device.maandengarantie)"
            class="btn btn-info m-1"><span class="material-icons">receipt</span> Garantie</button>
          <button v-if="!device.processed" v-on:click="processImages(device.docId, workingSchool, device)"
            class="btn btn-info m-1" :disabled="device.currentlyProcessing || device.processed"><span
              class="material-icons">memory</span></button>
          <button v-if="device.processed" v-on:click="processImages(device.docId, workingSchool, device)"
            class="btn btn-info m-1" :disabled="device.currentlyProcessing || device.processed"><span
              class="material-icons">memory</span></button>
          <DeviceModal v-if="device.devicetype !== 'chromebook-chromebox'" :device="device" :index="index"
            :school="workingSchool" :activeModalId="activeModalId"/>

          <ChromeDeviceModal v-if="device.devicetype === 'chromebook-chromebox'" :device="device" :index="index"
            :school="workingSchool" :activeModalId="activeModalId" />
        </div>
      </div>
      <!--
    <div
      class="devicecard"
      v-for="(device, index) in devices"
      :key="device.key"
    >
    <div v-if="previewImageOnly(device['imagesprocessed']).length > 0 ">
      <div
        class="imagecontainer"
        v-for="(image, index) in previewImageOnly(device['imagesprocessed'])"
        :key="'imageKey_' + index"
      >

          <img
            :src="image.dl_thumb"
            class="rounded mx-auto img-fluid"
            style="max-height: 100px"
          />
          <div v-if="device.currentlyProcessing" class="text-muted">
            <span class="material-icons"> hourglass_bottom </span> BEZIG... EVEN
            WACHTEN
          </div>
          <div v-if="( device.keywords  || device.foundText || device.logoResults)">
            Afbeeldingen gescand...
          </div>
          <div v-else>
            Nog niet verwerkt...
          </div>

      </div>
</div>
      <div class="buttoncontainer">
  <button
            v-on:click="removeDevice(device.docId, workingSchool)"
            class="btn btn-danger mt-4"
          >
            <span class="material-icons">
delete_forever
</span> Verwijder
          </button>
          <button
            v-on:click="
              controleerGarantie(
                device.inbedrijfstelling,
                device.maandengarantie
              )
            "
            class="btn btn-info mt-4"
          >
            <span class="material-icons">
receipt
</span> Garantie
          </button>
          <button
            v-if="!device.processed"
            v-on:click="processImages(device.docId, workingSchool, device)"
            class="btn btn-info mt-4"
            :disabled="device.currentlyProcessing || device.processed"
          >
           <span class="material-icons">
memory
</span> Analyseer
          </button>
          <button
            v-if="device.processed"
            v-on:click="processImages(device.docId, workingSchool, device)"
            class="btn btn-info mt-4"
            :disabled="device.currentlyProcessing || device.processed"
          >
           <span class="material-icons">
memory
</span> Geanalyseerd
          </button>

      </div>
      <div class="modalcontainer">

        <DeviceModal v-if="device.devicetype !== 'chromebook-chromebox'"
          class="mt-2 w-50"
          :device="device"
          :index="index"
          :school="workingSchool"
          :activeModalId="activeModalId"
        />

        <ChromeDeviceModal v-if="device.devicetype === 'chromebook-chromebox'"
                  class="mt-2 w-50"
          :device="device"
          :index="index"
          :school="workingSchool"
          :activeModalId="activeModalId" />
      </div>
    </div>

-->

    </div>
  </div>
</template>
<style scoped>
.textfragment {
  text-decoration: underline;
  margin: 10px;
}

.devicecard {
  background-color: whitesmoke;
  margin: 10px;
  padding: 10px;
  border-radius: 5px
}

.list-group-item {
  padding-left: 5px !important;
  padding-right: 5px !important
}

.filteredview {
  background-color: #ffc107;
}
</style>
<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'
import DeviceModal from '@/components/DeviceModal.vue'
import ChromeDeviceModal from '@/components/ChromeDeviceModal.vue'
import DeviceIcons from '@/components/DeviceIcons.vue'
import DeviceColorBar from '@/components/DeviceColorBar.vue'
import SchoolSelector from '@/components/SchoolSelector.vue'
export default {
  components: {
    DeviceModal,
    ChromeDeviceModal,
    DeviceIcons,
    DeviceColorBar,
    SchoolSelector
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user',
      workingSchool: 'workingSchool',
      activeModalId: 'activeModalId',
      unsubscribers: 'unsubscribers',
      toastrOptions: 'toastrOptions',
      case_fold_options: 'case_fold_options',
      exportableFields: 'exportableFields',
      bulk_editable_fields: 'bulk_editable_fields'
    }),
    filteredSchools () {
      var allschools = this.user.claims.schools
      var filtered = []
      for (var key in allschools) {
        if (allschools[key] === true) {
          filtered.push(key)
        }
      }
      console.log('FILTERED SCHOOLS = ')
      console.log(filtered)
      return filtered
    }
  },
  data () {
    return {
      devices: [],
      busy: false,
      toggleView: true,
      toggleListViewFields: false,
      listViewFields: ['title', 'merk', 'prijs', 'serienummer'],
      filteredVersion: false,
      showFilters: false,
      showExport: false,
      showBulkTools: false,
      bulkEditDocs: [],
      bulkEditField: false,
      bulkEditValue: '',
      exportFields: [],
      anyTextFilter: null,
      devicetypefilter: null,
      vastaanmuurfilter: null,
      uitgeleendfilter: null,
      teverzekerenfilter: null,
      doelgroeptoestelfilter: null,
      voorraadfilter: null,
      arbeidsmiddelfilter: null,
      defectfilter: null,
      textfilter: null,
      textfieldfilter: null,
      recentUserFilter: null,
      krijgtnogupdatesfilter: null,
      textfieldfilterstatus: false,
      diskusageFilter: null,
      orderByFilter: null,
      ssUrl: false,
      quickSearch: null,
      updateTheChosenSchoolPlease: 0
    }
  },
  created () {
    if (this.workingSchool === '') {
      this.chosenSchool = false
    } else {
      this.chosenSchool = this.workingSchool
    }

    console.log('ROUTE:' + JSON.stringify(this.$route.query))
    if (this.$route.query.school) {
      this.chosenSchool = this.$route.query.school
    }

    if (this.$route.query.school && this.$route.query.deviceId) {
      this.chosenSchool = this.$route.query.school
      console.log('Trying to open this device... %s ', this.$route.query.deviceId)
      this.$store.dispatch('setActiveModalId', this.$route.query.deviceId)
      this.listDevices(false, this.activeModalId)
    } else {
      console.log('Alles tonen')
      this.listDevices(false)
    }
  },
  watch: {
    devices: function (val, oldVal) {
      if (val.length !== 0) {
        this.busy = false
      }
    },
    quickSearch: function (val, oldVal) {
      // try to split val on - and if it works, do a search on sgassetid
      if (val === '') {
        this.listDevices()
      }
      if (val.length < 8) {
        return false
      }
      console.log('Trying to search on sgassetid')
      if (val.includes('-')) {
        var split = val.split('-')
        if (split.length === 2) {
          console.log('Trying to search on sgassetid')
          // pass split[0] (the school) to schoolSelector.vue
          this.chosenSchool = split[0]
          // Push the school to the URL
          this.$router.push('/toestellen?school=' + this.chosenSchool)
          this.updateTheChosenSchoolPlease = this.updateTheChosenSchoolPlease + 1
          this.workingSchool = split[0]
          if (split[1].length === 4) {
            this.anyTextFilter = val
            this.listDevices(true, false)
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      urlify: 'urlify'
    }),
    updateSchoolsChosenAndListDevices (schools) {
      if (this.quickSearch) {
        this.listDevices(true, false)
        return false
      }
      console.log('I was passed ', schools)
      this.chosenSchool = schools
      this.listDevices()
    },
    clearFilters: function (autorenewlist) {
      if (autorenewlist) { this.listDevices() }
      this.devicetypefilter = null
      this.vastaanmuurfilter = null
      this.uitgeleendfilter = null
      this.teverzekerenfilter = null
      this.voorraadfilter = null
      this.arbeidsmiddelfilter = null
      this.defectfilter = null
      this.textfilter = null
      this.doelgroeptoestelfilter = null
      this.textfieldfilter = null
      this.recentUserFilter = null
      this.textfieldfilterstatus = false
      this.diskusageFilter = null
      this.krijgtnogupdatesfilter = null
      this.orderByFilter = null
      this.anyTextFilter = null
    },
    listDevices: async function (filterArray) {
      this.busy = true
      console.log('Busy', this.busy)
      this.devices = []
      this.$toastr.info(
        'Gegevens worden opgehaald.',
        'Momentje...',
        this.toastrOptions
      )
      this.$forceUpdate()
      // startFilter is eigenlijk gewoon remove all unsubscribers..
      await this.$store.dispatch('startFilter')
      console.log('LISTING DEVICES...')
      if (this.workingSchool !== this.chosenSchool) {
        this.$store.dispatch('setSavedSchool', this.chosenSchool)
        if (this.activeModalId !== '') {
          this.$router.push('/toestellen?school=' + this.workingSchool + '&deviceId=' + this.activeModalId)
        } else {
          this.$router.push('/toestellen?school=' + this.workingSchool)
        }
      }
      if (!this.workingSchool) {
        this.$toastr.clear()
        this.$toastr.error(
          'Kies eerst een school uit de lijst!',
          'OPGEPAST',
          this.toastrOptions
        )
        return false
      }
      console.log('Getting devices for school ' + this.workingSchool)
      var totalValue = 0
      var ref = fb.db.collection(this.workingSchool)

      if (filterArray === true) {
        this.$toastr.warning(
          'Een gefilterde lijst is statisch en wordt niet realtime bijgewerkt! Als je hier met meerdere mensen in werkt, zie je geen realtime aanpassingen van collega\'s in de toestellen. Klik na elke bewerken even op \'Gegevens filteren\' om de laatste gegevens binnen te halen.',
          'OPGEPAST',
          this.toastrOptions
        )
        console.log('We gaan filteren...')
        this.$store.dispatch('startFilter')

        try {
          this.filteredVersion = true
          filterArray = [

            { type: 'devicetype', searchfor: this.devicetypefilter, operator: '==' },
            { type: 'vastaanmuur', searchfor: this.vastaanmuurfilter, operator: '==' },
            { type: 'teverzekeren', searchfor: this.teverzekerenfilter, operator: '==' },
            { type: 'uitgeleend', searchfor: this.uitgeleendfilter, operator: '==' },
            { type: 'doelgroeptoestel', searchfor: this.doelgroeptoestelfilter, operator: '==' },
            { type: 'voorraad', searchfor: this.voorraadfilter, operator: '==' },
            { type: 'arbeidsmiddel', searchfor: this.arbeidsmiddelfilter, operator: '==' },
            { type: 'defect', searchfor: this.defectfilter, operator: '==' },
            { type: 'ontvangtNogUpdates', searchfor: this.krijgtnogupdatesfilter, operator: '==' },
            { type: this.textfieldfilter, searchfor: this.textfilter, operator: '==', filterstatus: this.textfieldfilterstatus },
            { type: 'recentUserArray', searchfor: this.recentUserFilter, operator: 'array-contains' },
            { type: 'diskusagePercentage', searchfor: this.diskusageFilter, operator: '>=' },
            { type: 'anyText', searchfor: this.anyTextFilter, operator: '==' }
          ]
          var activefilters = filterArray.filter(function (obj) { if (obj.searchfor !== null) { return true } })
          console.log(activefilters)
          this.devices = []

          ref.onSnapshot((snapshot) => {
            snapshot.forEach((doc) => {
              var amountOfMatches = 0
              var device = doc.data()
              device.docId = doc.id
              // If this.devices already contains an object with this docId, remove it first
              var index = this.devices.findIndex(x => x.docId === device.docId)
              if (index > -1) {
                this.devices.splice(index, 1)
              }

              var lowerCasedDocData = JSON.stringify(device).toLowerCase()

              // ANY TEXT
              if ((typeof this.anyTextFilter === 'string') && (lowerCasedDocData.indexOf(this.anyTextFilter.toLowerCase()) > -1)) {
                console.log('anyTextFilter found: %s', this.anyTextFilter.toLowerCase())
                amountOfMatches++
              }

              // DEVICETYPE
              if ((typeof this.devicetypefilter === 'string') && (device.devicetype === this.devicetypefilter)) {
                console.log('devicetypefilter found: %s', this.devicetypefilter)
                amountOfMatches++
              }

              // TE VERZEKEREN
              if ((typeof this.teverzekerenfilter === 'string') && (device.teverzekeren === (this.teverzekerenfilter === 'true'))) {
                console.log('teverzekerenfilter found: %s', this.teverzekerenfilter === 'true')
                amountOfMatches++
              }

              // DOELGROEP
              if ((typeof this.doelgroeptoestelfilter === 'string') && (device.orgUnitPath_insensitive.indexOf(this.doelgroeptoestelfilter) > -1)) {
                console.log('teverzekerenfilter found: %s', this.teverzekerenfilter === 'true')
                amountOfMatches++
              }

              // VAST AAN MUUR
              if ((typeof this.vastaanmuurfilter === 'string') && (device.vastaanmuur === (this.vastaanmuurfilter === 'true'))) {
                console.log('vastaanmuurfilter found: %s', this.vastaanmuurfilter === 'true')
                amountOfMatches++
              }

              // DEFECT
              if (!device.defect) { device.defect = false }
              if ((typeof this.defectfilter === 'string') && (device.defect === (this.defectfilter === 'true'))) {
                console.log('vastaanmuurfilter found: %s', this.defectfilter === 'true')
                amountOfMatches++
              }

              // UITGELEEND
              if (!device.uitgeleend) { device.uitgeleend = false }
              if ((typeof this.uitgeleendfilter === 'string') && (device.uitgeleend === (this.uitgeleendfilter === 'true'))) {
                console.log('uitgeleendfilter found: %s', this.uitgeleendfilter === 'true')
                amountOfMatches++
              }
              // VOORRAAD
              if (!device.voorraad) { device.voorraad = false }
              if ((typeof this.voorraadfilter === 'string') && (device.voorraad === (this.voorraadfilter === 'true'))) {
                console.log('voorraadfilter found: %s', this.voorraadfilter === 'true')
                amountOfMatches++
              }
              // ARBEIDSMIDDEL
              if (!device.arbeidsmiddel) { device.arbeidsmiddel = false }
              if ((typeof this.arbeidsmiddelfilter === 'string') && (device.arbeidsmiddel === (this.arbeidsmiddelfilter === 'true'))) {
                console.log('arbeidsmiddelfilter found: %s', this.arbeidsmiddelfilter === 'true')
                amountOfMatches++
              }
              // ONTVANGT NOG UPDATES
              if (!device.ontvangtNogUpdates) { device.ontvangtNogUpdates = false }
              if ((typeof this.krijgtnogupdatesfilter === 'string') && (device.ontvangtNogUpdates === (this.krijgtnogupdatesfilter === 'true'))) {
                console.log('krijgtnogupdatesfilter found: %s', this.krijgtnogupdatesfilter === 'true')
                amountOfMatches++
              }
              // RECENTE GEBRUIKER
              if ((typeof this.recentUserFilter === 'string') && device.recentUserArray) {
                console.log('recentUserFilter found: %s', this.recentUserFilter)

                if (device.recentUserArray.indexOf(this.recentUserFilter) > -1) { amountOfMatches++ }
              }

              // DISK USAGE
              if ((typeof this.diskusageFilter === 'string') && device.diskusagePercentage) {
                console.log('diskusageFilter found: %s ===> device: %s', this.diskusageFilter, device.diskusagePercentage)
                console.log(parseInt(device.diskusagePercentage) >= parseInt(this.diskusageFilter))
                if (parseInt(device.diskusagePercentage) >= parseInt(this.diskusageFilter)) { amountOfMatches++ }
              }
              // PUSH IF MATCHED
              if (amountOfMatches === activefilters.length) {
                this.devices.push(device)
                if ((device.teverzekeren === true) && (device.prijs !== '')) {
                  // console.log('Te verzekeren device: %s', device.title)
                  // console.log('Adding %s', device.prijs)
                  totalValue = Number(totalValue) + Number(device.prijs)
                }
              }
            })
            console.log('TOTALE WAARDE: %s', totalValue)
            console.log('Got %s devices', this.devices.length)
            if ((typeof this.orderByFilter === 'string')) {
              console.log('Sorteren op %s', this.orderByFilter)
              console.log(this.devices)
              const customSorter = function (property) {
                console.log('I SORT ON %s', property)
                var sortOrder = 1
                return function (a, b) {
                  /* next line works with strings and numbers,
           * and you may want to customize it to your needs
           */
                  if (!a[property]) { a[property] = '-' }
                  if (!b[property]) { b[property] = '-' }
                  var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
                  return result * sortOrder
                }
              }
              this.devices.sort(customSorter(this.orderByFilter))
              // this.busy = false
              this.$forceUpdate()
              return true
            }
          })
          // var solofilter = false
          // var noOrdering = false
          /*   if (this.diskusageFilter !== '' && this.diskusageFilter !== false && !isNaN(parseInt(this.diskusageFilter))) {
            console.log('Er wordt enkel hierop gefilterd. Dit kan niet gecombineerd worden...')

            ref = ref.where('diskusagePercentage', '>=', parseInt(this.diskusageFilter))
            //   noOrdering = true

            //   solofilter = true
            this.$toastr.info('Er wordt enkel gefilterd op schijfgebruik. Deze filter kan niet gecombineerd worden met andere filters.', 'Opgepast', this.toastrOptions)
          }
          if (this.anyTextFilter !== '') {
            ref.onSnapshot((snapshot) => {
              this.devices = []
              snapshot.forEach((doc) => {
                var device = doc.data()
                device.docId = doc.id
                var lowerCasedDocData = JSON.stringify(device).toLowerCase()
                if (lowerCasedDocData.indexOf(this.anyTextFilter.toLowerCase()) > -1) {
                  this.devices.push(device)
                }

                // console.log(device)
              })
            })
            return true
          } */
          /*          if (!solofilter) {
            console.log('er wordt gefilterd...')
            console.table(filterArray)
            filterArray.forEach((filterObj) => {
            // indien textveld-filter:
              if (filterObj.type === this.textfieldfilter) {
                if (filterObj.searchfor === '' && filterObj.filterstatus) {
                  console.log('looking for blank %s', this.textfieldfilter, this.textfieldfilter + '_insensitive', '==', '')
                  ref = ref.where(this.textfieldfilter + '', '==', null); return true
                }
                if (filterObj.searchfor === '' && !filterObj.filterstatus) {
                  console.log('skipping', filterObj); return false
                }
                console.log('applying:', this.textfieldfilter + '_insensitive', '>=', filterObj.searchfor.toLowerCase())

                try { filterObj.searchfor = filterObj.searchfor.toLowerCase() } catch (e) {}
                ref = ref.where(this.textfieldfilter + '_insensitive', '>=', filterObj.searchfor).where(this.textfieldfilter + '_insensitive', '<=', filterObj.searchfor + '\uf8ff')
                noOrdering = true
              } else if (filterObj.type === 'recentUserArray') {
              // INDIEN RECENT USERSARRAY
                if (filterObj.searchfor === '') { console.log('skipping', filterObj); return false }
                console.log('Applying', filterObj.type, filterObj.operator, filterObj.searchfor, typeof filterObj.searchfor)
                ref = ref.where(filterObj.type, filterObj.operator, filterObj.searchfor)
              } else {
              // INDIEN GEWONE FILTER
                if (filterObj.searchfor === 'true') { filterObj.searchfor = true }
                if (filterObj.searchfor === 'false') { filterObj.searchfor = false }
                if (filterObj.searchfor === null || filterObj.searchfor === '') { console.log('Skipping', filterObj); return false }
                console.log('--- Applying', filterObj.type, filterObj.operator, filterObj.searchfor, typeof filterObj.searchfor)
                ref = ref.where(filterObj.type, filterObj.operator, filterObj.searchfor)
                noOrdering = true
              }
            })
          }
          if ((this.orderByFilter !== '' || this.orderByFilter !== 'false') && noOrdering === false) {
            console.log('ordering...')
            ref = ref.orderBy('added')
          }
          this.devices = []
          ref.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              var device = doc.data()
              // console.log(device)
              this.devices.push(device)
            })

            console.log('aantal gevonden devices: %s', this.devices.length)
            this.bezigMetOphalen = false
            return true
          }).catch(async (e) => {
            if (e.message.indexOf('The query requires an index.') > -1) {
              var adminMessage = await this.$store.dispatch('getUrls', e.message.replace('The query requires an index. You can create it here:', ''))
              e = await this.$store.dispatch('urlify', e.message.replace('The query requires an index. You can create it here:', ''))
              fb.db.collection('indexes_todo').add({ message: adminMessage, email: this.user.claims.email, school: this.workingSchool })
              this.$toastr.clear()
              this.$toastr.error(
                'Er moet een index gemaakt worden...Klik op de knop om een mail te sturen met de nodige info. <br> ' + e,
                'OPGEPAST',
                this.toastrOptionsHardToast
              )
            }
          }) */
        } catch (e) { alert(e) }
      } else {
        console.log("Geen filter... Let's get everything in this school...")
        console.log('ActiveModalId ingesteld op %s', this.activeModalId)

        this.filteredVersion = false
        ref = ref.orderBy('added')
        var newUnsubscriber = ref.onSnapshot((snapshot) => {
          this.devices = []
          snapshot.forEach((doc) => {
            var device = doc.data()
            device.docId = doc.id
            // console.log(device)
            if ((device.teverzekeren === true) && (device.prijs !== '')) {
              // console.log('Te verzekeren device: %s', device.title)
              // console.log('Adding %s', device.prijs)
              totalValue = Number(totalValue) + Number(device.prijs)
            }
            this.devices.push(device)
          })
          // console.log('TOTALE WAARDE: %s', totalValue)
          // console.log(this.devices)
        })
        this.$store.dispatch('addUnsubscriber', newUnsubscriber)
      }
      // this.busy = false
      console.log('Finished! busy', this.busy)
      this.$toastr.clear()
      // console.log('TOTALE WAARDE: %s', totalValue)
      this.$forceUpdate()
    },
    updateDevice: function (key) {
      console.log('updating ', key)
    },
    exportCurrentListToGoogleSheets: async function () {
      console.log('Trying to fix you a Google Sheet')
      // console.log(this.user)
      // console.log('Bearer ' + this.user.googleAccessToken)
      try {
        var resultSheetCreation = await fetch('https://sheets.googleapis.com/v4/spreadsheets?key=' + 'AIzaSyC4oMBZ8aiQzgMXKLIPDZmIQ-HRGuAiQ1o', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.user.googleAccessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            properties: { title: 'INVENTARIS EXPORT - ' + this.workingSchool + ' ' + new Date().toLocaleString() },
            sheets: [{ properties: { title: 'INVENTARIS' } }
            ]
          })
        })
        // var jsonresponse = await result// .data()
        console.log(resultSheetCreation)
        if (resultSheetCreation.status !== 200) {
          this.$toastr.error('Probleem bij het maken van je sheet. Ik log je even uit en terug in. Probeer daarna even opnieuw.', 'Fout!', this.toastrOptions)
          this.$store.dispatch('logout')
          this.$store.dispatch('login', { route: '/toestellen?school=' + this.workingSchool })
          router.push('/toestellen?school=' + this.workingSchool).catch(() => { })
          return false
        }
        var jsonresponseSheetCreation = await resultSheetCreation.json()
        console.log(jsonresponseSheetCreation)

        var ssid = jsonresponseSheetCreation.spreadsheetId
        var ssUrl = jsonresponseSheetCreation.spreadsheetUrl
      } catch (err) {
        this.$toastr.error('Probleem bij het maken van je sheet: ' + err, 'Fout!', this.toastrOptions)
        return false
      }
      try {
        var resultFillSheet = await fetch('https://sheets.googleapis.com/v4/spreadsheets/' + ssid + '/values/' + escape('INVENTARIS!A:A') + ':append?valueInputOption=USER_ENTERED&key=' + 'AIzaSyC4oMBZ8aiQzgMXKLIPDZmIQ-HRGuAiQ1o', {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + this.user.googleAccessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            values: this.exportCurrentList(true)

          })
        })
        var jsonresponseFillSheet = await resultFillSheet.json()
        console.log(jsonresponseFillSheet)
      } catch (errfill) {
        this.$toastr.error('De sheet werd gemaakt, maar de gegevens konden niet worden weggeschreven: ' + errfill, 'Fout!', this.toastrOptions)
        return false
      }
      this.ssUrl = ssUrl
      this.$toastr.success('Sheet werd gemaakt', 'Gelukt!', this.toastrOptionsHardToast)

      // .then(result => result.json()).then(data => console.log(data))
      return true
    },
    exportCurrentList: function (getArray) {
      console.log('Exporting these fields', this.exportFields)
      console.log('Exporting these documents...', this.devices)
      // https://stackoverflow.com/questions/17564103/using-javascript-to-download-file-as-a-csv-file/17564369
      var arr = []

      var firstRow = []
      this.exportFields.map((item) => { firstRow.push(this.exportableFields[item].forEndUsers) })
      firstRow.push('Rechtstreekse link')
      arr.push(firstRow)

      this.devices.map((device) => {
        var deviceRow = []
        this.exportFields.forEach((key) => { if (device[key]) { deviceRow.push(device[key]) } else { deviceRow.push('') } })
        deviceRow.push('https://inventaris.sgfv.be/toestellen?school=' + device.school + '&deviceId=' + device.docId)
        arr.push(deviceRow)
      })
      console.log(arr)
      if (getArray) { return arr }
      var csv = arr
        .map(v =>
          v.map(x => (isNaN(x) ? `"${x.replace(/"/g, '""')}"` : x)).join(',')
        )
        .join('\n')
      csv = 'data:text/csv;charset=utf-8,' + escape(csv)
      return csv
      // return csv
    },
    processImages: async function (docId, school, device) {
      this.$toastr.info('Bezig met analyseren...', 'Even geduld...', this.toastrOptions)
      console.log('Calling process images ', docId, school)
      var processImages = fb.fireb.functions().httpsCallable('processImages')
      try {
        var result = await processImages({ school: school, docId: docId, devicetype: device.devicetype })
        this.$toastr.info(result, 'Klaar!', this.toastrOptions)
        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
      }
    },
    previewImageOnly: function (images) {
      if (!images) {
        return false
      }
      var previewImage = images.find((imObj) => { return imObj.preview === true })
      if (!previewImage) {
        return images[0].dl_thumb
      }
      return previewImage.dl_thumb // return images.filter((image) => image.preview === true)
    },
    controleerGarantie: async function (inbedrijfstelling, maandengarantie) {
      var nogInGarantie = await this.$store.dispatch('controleerGarantie', {
        inbedrijfstelling: inbedrijfstelling,
        maandengarantie: maandengarantie
      })
      console.log('Nog in garantie: %s', nogInGarantie)
      if (nogInGarantie === 'insufficient_data') {
        this.$toastr.warning('Onvoldoende gegevens om dit te berekenen...', 'Hmm...', this.toastrOptions)
        return false
      }
      if (nogInGarantie) {
        this.$toastr.success('Nog in garantie!', 'Goed nieuws', this.toastrOptions)
      } else {
        this.$toastr.warning('Spijtig... Geen garantie meer', 'Jammer', this.toastrOptions)
      }
      return true
    },
    quickFilter: function (obj) {
      this.clearFilters(false)
      for (const key in obj) {
        this[key] = obj[key]
      }
      this.listDevices(true)
    },
    bulkEdit: async function () {
      if (this.bulkEditDocs.length === 0 || this.bulkEditField === false || this.bulkEditValue === '') {
        this.$toastr.warning('Eerst de nodige velden invullen aub!', 'Jammer', this.toastrOptions)
      }
      const amount = this.bulkEditDocs.length
      console.log('VELD DAT WORDT AANGEPAST: ', this.bulkEditField)
      for (const doc of this.bulkEditDocs) {
        //  this.bulkEditDocs.forEach(async (doc, index) => {
        // Enkele mappings doen, zodat de zoekfuncties in firebase werken zoals de andere toestellen:

        if (doc.devicetype === 'chromebook-chromebox') {
          if (this.bulkEditField === 'notes') {
            doc.notes = this.bulkEditValue
            doc.g.notes = this.bulkEditValue
          } else if (this.bulkEditField === 'locatie') {
            doc.locatie = this.bulkEditValue
            doc.g.annotatedLocation = this.bulkEditValue
          } else if (this.bulkEditField === 'sgassetid') {
            doc.sgassetid = this.bulkEditValue
            doc.g.annotatedAssetId = this.bulkEditValue
          } else if (this.bulkEditField === 'orgUnitPath') {
            doc.orgUnitPath = this.bulkEditValue
            doc.g.orgUnitPath = this.bulkEditValue
          }
        }
        if (this.bulkEditValue === 'false') { this.bulkEditValue = false }
        if (this.bulkEditValue === 'true') { this.bulkEditValue = true }

        doc[this.bulkEditField] = this.bulkEditValue

        var newDocCaseFolded = await this.$store.dispatch('caseFoldDoc', doc)
        console.log('CASE FOLDED NEW DOC', newDocCaseFolded)
        // INDIEN EEN CHROMEBOOK >> DEVICE UPDATEN IN ADMIN...
        if (doc.devicetype === 'chromebook-chromebox') {
          var updateChromeDevice = fb.functions.httpsCallable('updateChromeDevice')
          try {
            var result = await updateChromeDevice({ school: newDocCaseFolded.school, docId: newDocCaseFolded.docId, device: newDocCaseFolded })
            console.log('---------SERVER RESP---------------')
            console.log(result)
            this.$toastr.clear()
            this.$toastr.info('Toestel met ID ' + newDocCaseFolded.docId + ' <br> bijgewerkt.', 'Klaar!', this.toastrOptions)
          } catch (err) {
            console.log('---------SERVER ERR---------------')
            console.log(err)
            this.$toastr.clear()
            this.$toastr.error(err, 'Fout!', this.toastrOptions)
            return false
          }
        } else {
          // IN ALLE ANDERE GEVALLEN: DOC BIJWERKEN IN FB
          try {
            await fb.db.collection(newDocCaseFolded.school).doc(newDocCaseFolded.docId).update(newDocCaseFolded, { merge: true })
            this.$toastr.clear()
            if (newDocCaseFolded.images) {
              await fb.db.collection(newDocCaseFolded.school).doc(newDocCaseFolded.docId).update({ images: fb.firestoreFieldvalue.delete() })
            }
            this.$toastr.info('Toestel met ID ' + newDocCaseFolded.docId + ' <br> bijgewerkt in de database.', 'Klaar!', this.toastrOptions)
          } catch (err) {
            console.log('---------FB ERR---------------')
            console.log(err)

            this.$toastr.error(err, 'Fout!', this.toastrOptions)
            return false
          }
        }
        // if (index === this.bulkEditDocs.length) {
        //   this.$toastr.clear()

        // }
      }
      this.$toastr.clear()
      this.$toastr.success('Er werden ' + amount + ' toestellen aangepast!', 'Klaar!', this.toastrOptions)
      // })
    }

  }
}

</script>
