<template >
  <div class="">
    <h1 class="text-white">Users beheren</h1>
    <div class="d-flex flex-row flex-wrap" >
      <div v-for="userItem in users" :key="userItem.key" class="container profile mb-2 p-2 pl-3 d-flex flex-wrap flex-row shadow-lg">
        <div class="d-flex w-100 mb-5">
          <div class="profileimage flex-shrink-1">
            <img class="rounded-circle " :src="userItem.photoURL" alt="" />
          </div>

          <div class="flex-fill">
            <h3>{{ userItem.name }}</h3>
            <div><i>{{ userItem.email }}</i></div>
             <span v-if="userItem.deleteuser">DELETION PENDING...</span>
          </div>
        </div>
        <div class="w-100">
          <div class="d-flex flex-wrap flex-row text-left">
            <div class="w-50">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.FEA"
                :id="'FEA'+userItem.uid"
                />
                <label class="form-check-label" :for="'FEA'+userItem.uid">
                  FEA
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.FMA"
                  :id="'FMA'+userItem.uid"
                />
                <label class="form-check-label" :for="'FMA'+userItem.uid">
                  FMA
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.FML"
                  :id="'FML'+userItem.uid"
                />
                <label class="form-check-label" :for="'FML'+userItem.uid">
                  FML
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.FMT"
                  :id="'FMT'+userItem.uid"
                />
                <label class="form-check-label" :for="'FMT'+userItem.uid">
                  FMT
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.FMP"
                  :id="'FMP'+userItem.uid"
                />
                <label class="form-check-label" :for="'FMP'+userItem.uid">
                  FMP
                </label>
              </div>
            </div>
            <div class="w-50">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.FMM"
                  :id="'FMM'+userItem.uid"
                />
                <label class="form-check-label" :for="'FMM'+userItem.uid">
                  FMM
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.FPP"
                  :id="'FPP'+userItem.uid"
                />
                <label class="form-check-label" :for="'FPP'+userItem.uid">
                  FPP
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.SGFV"
                  :id="'SGFV'+userItem.uid"
                />
                <label class="form-check-label" :for="'SGFV'+userItem.uid">
                  SGFV
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.schools.FFF"
                  :id="'SGFV'+userItem.uid"
                />
                <label class="form-check-label" :for="'SGFV'+userItem.uid">
                  FFF - DEMO
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.superuser"
                  :id="'SUPERUSER'+userItem.uid"
                  :disabled="userItem.email === 'jaspercuvelier@sgfv.be'"
                />
                <label class="form-check-label" :for="'SUPERUSER'+userItem.uid">
                  SUPERUSER
                </label>
              </div>
                <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  v-model="userItem.provisioned"
                  :id="'PROVISIONED'+userItem.uid"

                />
                <label class="form-check-label" :for="'PROVISIONED'+userItem.uid">
                  PROVISIONED?
                </label>
              </div>

            </div>
          </div>
        </div>
        <div class="w-100 border-top pt-2">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" class="btn btn-danger"  v-on:click="deleteUser(userItem.uid)"  :disabled="userItem.superuser === true">DELETE USER</button>
            <button type="button" class="btn btn-primary" v-on:click="saveUser(userItem)" >SAVE CHANGES</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.profile {
  background:whitesmoke;
    border-radius: 25px;
    max-width: 400px;
}
.profileimage {
  width: 96px;
  height: 96px;
}
</style>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import * as fb from '../firebase'

export default {
  data () {
    return {
      users: []
    }
  },
  created () {
    console.log('users are being fetched...')
    this.getUsers()
    console.log(this.users)
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user',
      unsubscribers: 'unsubscribers',
      toastrOptions: 'toastrOptions'
    })
  },

  methods: {
    saveUser: async function (userItem) {
      this.$toastr.clear()
      this.$toastr.info(
        'Bezig met opslaan...',
        'Even wachten',
        this.toastrOptions
      )
      console.log('saving changes to user... ')
      console.log(userItem)
      await fb.usersCollection.doc(userItem.uid).update({ provisioned: userItem.provisioned, schools: userItem.schools, superuser: userItem.superuser }, { merge: true })
      this.$toastr.clear()
      this.$toastr.success(
        'Gegevens opgeslagen.',
        'Klaar!',
        this.toastrOptions
      )
    },
    deleteUser: async function (uid) {
      this.$toastr.clear()
      console.log('Scheduling deletion for user: ' + uid)
      this.$toastr.info(
        'Bezig met verwijderen...',
        'Even wachten',
        this.toastrOptions
      )
      await fb.usersCollection.doc(uid).update({ deleteuser: true }, { merge: true })
      this.$toastr.clear()
      this.$toastr.success(
        'Gegevens opgeslagen.',
        'Klaar!',
        this.toastrOptions
      )
    },
    getUsers: function () {
      var newUnsubscriber = fb.usersCollection.onSnapshot((snapshot) => {
        this.users = []
        snapshot.forEach((doc) => {
          var userItem = doc.data()
          console.log(userItem)
          this.users.push(userItem)
        })
        console.log(this.users)
      })
      this.$store.dispatch('addUnsubscriber', newUnsubscriber)
    }
  }
}
</script>
