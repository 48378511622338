<template>
  <div>
    <!-- Button trigger modal -->
<button type="button" v-on:click="updateRouterPathToCurrentDevice (device.school, device.docId)" class="btn btn-primary" :id="'modal-button-'+device.school+'-'+device.docId" data-toggle="modal" :data-target="'#modal-edit-'+device.school+'-'+device.docId" :ref="'modal-button-'+device.school+'-'+device.docId">
  <span class="material-icons">
edit
</span> Aanpassen
</button>

<!-- Modal -->
<div class="modal fade text-dark" :id="'modal-edit-'+device.school+'-'+device.docId" data-backdrop="static" data-keyboard="false" tabindex="-1" :aria-labelledby="'device-title-header-' + device.docId" aria-hidden="true">
  <div class="modal-dialog  modal-lg" :ref="'modal-'+device.school+'-'+device.docId">

    <div class="modal-content">
      <div class="modal-header">

          <div class=" modal-title w-100 text-center">
            <h3 class="mb-0">{{device.title}}</h3>

            <div class="mb-0" >
              <div class=" text-muted d-flex flex-row flex-wrap flex-grow-1 justify-content-between align-items-center">
              <div class="small flex-grow-1 text-right pr-1"><i><span class="material-icons">post_add</span>Toegevoegd op {{device.added}} door Google Bot</i></div>

              <img loading="lazy" src="@/assets/robot_profile_pic.jpg" height="40px" class="rounded-circle">
              </div>
              <div class=" text-muted d-flex flex-row flex-wrap flex-grow-1 justify-content-between align-items-center">
              <div class="small flex-grow-1 text-right pr-1"><i><span class="material-icons text-muted">edit</span>Aangepast op {{device.lastEditAt}} door {{device.lastEditBy_displayname}}</i></div>

              <img loading="lazy" :src="device.lastEditBy_photoUrl" height="40px" class="rounded-circle">
              </div>
              <DeviceIcons class="mt-2" :device="device" />
              </div>
          </div>

        <button type="button" class="close" v-on:click="updateRouterPathBackToSchool(device.school)" data-dismiss="modal" aria-label="Close" :ref="'modal-close-button-'+device.school+'-'+device.docId">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div class="modal-body">
<DeviceColorBar :device="device" />
<!-- NAAM -->
        <div class="form-group row">
          <label :for="'device-title_'+ device.docId" class="col-sm-4 col-form-label">Geef een school-ID op:</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" :id="'device-title_'+ device.docId" v-model="device.title"  :disabled="device.currentlyProcessing" >
            </div>
        </div>

<!-- SGFV NAAM -->
        <div class="form-group row">
          <label :for="'device-sg-title_'+ device.docId" class="col-sm-4 col-form-label">Geef een SGFV-ID op:</label>
          <div class="col-sm-8">
            <input type="text" class="form-control" :id="'device-sg-title_'+ device.docId" v-model="device.g.annotatedAssetId"  :disabled="device.currentlyProcessing" >
            </div>
        </div>

<!-- TOESTELTYPE -->
  <div class="form-group row">
        <label  :for="'device-type_'+ device.docId" class="col-sm-4 col-form-label">Toesteltype</label>
        <div class="col-sm-8">
        <select class="custom-select" v-model="device.devicetype" :id="'device-type_'+ device.docId" :disabled="device.currentlyProcessing || device.devicetype === 'chromebook-chromebox'">
          <option value="computer">COMPUTER</option>
          <option value="chromebook-chromebox">CHROMEBOOK / CHROMEBOX</option>
          <option value="digitaalbord">DIGITAAL BORD</option>
          <option value="beamer">BEAMER</option>
          <option value="scherm">BEELDSCHERM</option>
          <option value="voip">VOIP TELEFOON</option>
          <option value="netwerkapparatuur">NETWERKAPPARATUUR</option>
          <option value="accesspoint">ACCESS POINT (WIFIBAKJE)</option>
          <option value="printer">PRINTER MFC</option>
          <option value="huishoudelectro">HUISHOUD-ELECTRO</option>
          <option value="overige">OVERIGE</option>
        </select>

        </div>

        </div>

<!-- LOCATIE -->
   <div class="form-group row">
    <label :for="'device-location-'+ device.docId" class="col-sm-4 col-form-label">Vaste plaats op school</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-location-'+ device.docId" v-model="device.g.annotatedLocation" :disabled="device.currentlyProcessing">
      <small class="text-muted">⚠️ Uitgeleend aan een persoon? <br>Zet dan een vinkje bij "Uitgeleend" en noteer de naam van de ontlener.</small>
    </div>
  </div>

<!-- MERK -->
   <div class="form-group row">
    <label :for="'device-merk-'+ device.docId" class="col-sm-4 col-form-label">Merk</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-merk-'+ device.docId" v-model="device.merk" :disabled="device.currentlyProcessing ||  !user.claims.superuser">
    </div>
  </div>

<!-- TYPE -->
   <div class="form-group row">
    <label :for="'device-type-serie-'+ device.docId" class="col-sm-4 col-form-label">Type/serie</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-type-serie'+ device.docId" v-model="device.typeserie" :disabled="device.currentlyProcessing ||  !user.claims.superuser">
    </div>
  </div>

<!-- SERIENUMMER -->
   <div class="form-group row">
    <label :for="'device-type-serienummer-'+ device.docId" class="col-sm-4 col-form-label">Serienummer</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-type-serienummer'+ device.docId" v-model="device.serienummer" disabled>
    </div>
  </div>

<!-- LEVERANCIER -->
   <div class="form-group row">
    <label :for="'device-type-leverancier-'+ device.docId" class="col-sm-4 col-form-label">Leverancier</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" :id="'device-type-leverancier'+ device.docId" v-model="device.leverancier" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- PRIJS -->
   <div class="form-group row">
    <label :for="'device-prijs-'+ device.docId" class="col-sm-4 col-form-label">Prijs</label>
    <div class="col-sm-8">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
         <span class="input-group-text">€</span>
        </div>
        <input type="number" class="form-control" :id="'device-prijs'+ device.docId" v-model="device.prijs" :disabled="device.currentlyProcessing">
    </div>

    </div>
  </div>

<!-- ORG -->
   <div class="form-group row">
    <label :for="'device-org-'+ device.docId" class="col-sm-4 col-form-label">Organisatie</label>
    <div class="col-sm-8">
      <div class="input-group mb-3">
        <input type="text" class="form-control" :id="'device-org'+ device.docId" v-model="device.g.orgUnitPath" :disabled="device.currentlyProcessing ||  !user.claims.superuser">
    </div>

    </div>
  </div>

<!-- INBEDRIJFSTELLING -->
   <div class="form-group row">
    <label :for="'device-inbedrijfstelling-'+ device.docId" class="col-sm-4 col-form-label">Datum Inbedrijfstelling</label>
    <div class="col-sm-8">
      <input type="date" class="form-control" :id="'device-inbedrijfstelling-'+ device.docId" v-model="device.inbedrijfstelling" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- UITBEDRIJFSTELLING -->
   <div class="form-group row">
    <label :for="'device-uitbedrijfstelling-'+ device.docId" class="col-sm-4 col-form-label">Datum Uitbedrijfstelling</label>
    <div class="col-sm-8">
      <input type="date" class="form-control" :id="'device-uitbedrijfstelling-'+ device.docId" v-model="device.uitbedrijfstelling" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- AANTAL MAANDEN GARANTIE SINDS INBDERIJFSTELLING -->
   <div class="form-group row">
    <label :for="'device-garantie-'+ device.docId" class="col-sm-4 col-form-label">Aantal maanden garantie</label>
    <div class="col-sm-8">
      <input type="number" min="0" class="form-control" :id="'device-garantie-'+ device.docId" v-model="device.maandengarantie" :disabled="device.currentlyProcessing">
    </div>
  </div>

<!-- TOEGEKENDE GEBRUIKER -->
   <div class="form-group row">
    <label :for="'device-annotatedUser-'+ device.docId" class="col-sm-4 col-form-label">Toegekend aan gebruiker</label>
    <div class="col-sm-8">
        <input type="text" class="form-control" :id="'device-annotatedUser-'+ device.docId" v-model="device.g.annotatedUser" :disabled="device.currentlyProcessing"/>
    </div>
  </div>

<!-- OPMERKINGEN -->
   <div class="form-group row">
    <label :for="'device-notes-'+ device.docId" class="col-sm-4 col-form-label">Opmerkingen</label>
    <div class="col-sm-8">
      <textarea class="form-control" :id="'device-notes-'+ device.docId" v-model="device.g.notes" :disabled="device.currentlyProcessing"></textarea>
    </div>
  </div>

<!-- BLIJFT ALTIJD OP SCHOOL -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-blijftaltijdopschoolcheck-'+ device.docId" v-model="device.vastaanmuur" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-blijftaltijdopschoolcheck-'+ device.docId">
          Blijft altijd op school en gaat nooit mee naar huis
        </label>
      </div>
    </div>
  </div>

<!-- TE VERZEKEREN -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-teverzekerencheck-'+ device.docId" v-model="device.teverzekeren" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-teverzekerencheck-'+ device.docId">
          Te verzekeren
        </label>
      </div>
    </div>
  </div>

<!-- UITGELEEND -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox"  :id="'device-uitgeleendcheck-'+ device.docId" v-model="device.uitgeleend" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-uitgeleendcheck-'+ device.docId">
          Uitgeleend
        </label>
      </div>
    </div>
  </div>
          <!-- UITGELEEND AAN -->
            <div v-if="device.uitgeleend" class="form-group row">
              <label :for="'device-uitgeleendaantext-'+ device.docId" class="col-sm-4 col-form-label">Uitgeleend aan</label>
              <div class="col-sm-8">
                <input type="text" class="form-control"  :id="'device-uitgeleendaantext-'+ device.docId" v-model="device.uitgeleendaan" :disabled="device.currentlyProcessing">
              </div>
            </div>

<!-- DEFECT -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-defectcheck-'+ device.docId" v-model="device.defect" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-defectcheck-'+ device.docId">
          Defect
        </label>
      </div>
    </div>
  </div>
          <!-- DEFECT OPMERKINGEN -->
            <div v-if="device.defect" class="form-group row">
              <label :for="'device-defecttext-'+ device.docId" class="col-sm-4 col-form-label">Defect omschrijving</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" :id="'device-defecttext-'+ device.docId" v-model="device.defectopmerking" :disabled="device.currentlyProcessing">
              </div>
            </div>
<!-- ARBEIDSMIDDEL -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-arbeidsmiddelcheck-'+ device.docId" v-model="device.arbeidsmiddel" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-arbeidsmiddelcheck-'+ device.docId">
          Arbeidsmiddel <small>Gereedschap zoals een grasmachine, boormachine, haagschaar... Belangrijk voor preventie</small>
        </label>
      </div>
    </div>
  </div>

<!-- VOORRAAD -->
<div class="form-group row">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" :id="'device-voorraadcheck-'+ device.docId" v-model="device.voorraad" :disabled="device.currentlyProcessing">
        <label class="form-check-label" :for="'device-voorraadcheck-'+ device.docId">
          Voorraad (niet in gebruik)
        </label>
      </div>
    </div>
  </div>

<!-- KRIJGT CHROMEDEVICE NOG UPDATES? -->
   <div v-if="device.devicetype === 'chromebook-chromebox'" class="form-group row">
    <label :for="'device-updatepolicy-'+ device.docId" class="col-sm-4 col-form-label">Ontvangt het toestel nog updates?</label>
    <div class="col-sm-8">
      <div class="input-group mb-3">
        <div type="text" class="form-control" :id="'device-updatepolicy'+ device.docId" ><span v-if="device.g.ontvangtNogUpdates">JA</span><span v-else>NEE</span></div>
    </div>

    </div>
  </div>

<!-- IMAGES -->

<div :id="'imageCaroussel-'+device.docId" class="carousel slide mb-2" data-ride="carousel">

  <ol class="carousel-indicators" v-if="device.imagesprocessed.length > 1" >
    <li v-for="(image, index) in device.imagesprocessed" :key="'device_imagesprocessed_'+index" :data-target="'#imageCaroussel-'+device.docId" :data-slide-to="index" class="active"></li>
  </ol>
  <div class="carousel-inner">
        <div class=" carousel-item " v-for="(image,index) in device.imagesprocessed" :key="'carousel_item_'+index"  :class="{active : index === 0}" data-interval="false">
          <div class="card pb-4"  >
            <img loading="lazy" :src="image.dl_thumb" class="card-img-top center-cropped " alt="Foto van het toestel">
            <div class="card-body">
              <div class="card-text">
                  <div v-if="device.imagesprocessed[index].tags && device.imagesprocessed[index].tags.length > 0">
                      <span v-for="(tag,tagindex) in image.tags" :key="'tag_'+tag+'tagindex_'+tagindex" class="badge badge-pill badge-secondary tags">{{tag}}
                      <span v-on:click="removeTagFromImage(index,tagindex)"  class="badge badge-pill badge-danger pointer">X</span>
                      </span>
                  </div>
                  <div>
                    <textarea  v-if="device.imagesprocessed[index].foundText && device.imagesprocessed[index].foundText.length > 0" class="form-control semitransp mt-1 mb-1" v-model="device.imagesprocessed[index].foundText" rows="4" :disabled="device.currentlyProcessing"></textarea>
                  </div>

              </div>
              <div class="btn-group btn-group-sm mt-2">
                <button class="btn btn-primary" v-on:click="updatepreviewImage(device.docId, device.school, index)" :disabled="device.imagesprocessed[index].preview"> <span class="material-icons">preview</span> Gebruik als preview </button>
                <button class="btn btn-danger" v-on:click="deleteThisImage(device.docId, device.school, index)" :disabled="!device.imagesprocessed[index].gs || device.imagesprocessed.length === 1"> <span class="material-icons">delete</span> Verwijder foto</button>
                <a class="btn btn-info" :href="image.dl_original" target="_blank" v-if="image.dl_original"> <span class="material-icons">open_in_new</span>Origineel</a>
              </div>
            </div>
          </div>
        </div>
  </div>

      <a class="carousel-control-prev" :href="'#imageCaroussel-'+device.docId" role="button" data-slide="prev">
        <span class="-carousel-control-prev-icon" aria-hidden="true"><span class="material-icons large text-dark">navigate_before</span></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" :href="'#imageCaroussel-'+device.docId" role="button" data-slide="next">
        <span class="-carousel-control-next-icon" aria-hidden="true"><span class="material-icons large text-dark">navigate_next</span></span>
        <span class="sr-only">Next</span>
      </a>
</div>

<!-- IMAGES
<div :id="'imageCaroussel-'+device.docId" class="carousel slide mb-2" data-ride="carousel">
  <ol class="carousel-indicators" >
    <li v-for="(image, index) in device.imagesprocessed" :key="index" :data-target="'#imageCaroussel-'+device.docId" :data-slide-to="index" class="active"></li>

  </ol>
  <div class="carousel-inner">
    <div v-for="(image,index) in device.imagesprocessed" :key="index" class="carousel-item" :class="{active : index === 0}">
      <img :src="image.dl" class="d-block center-cropped m-auto" alt="Foto van het toestel">
      <div class="carousel-caption d-none d-md-block">

        <div><span v-for="(tag,tagindex) in image.tags" :key="tag" class="badge badge-pill badge-secondary tags">{{tag}}
           <span v-on:click="removeTagFromImage(index,tagindex)"  class="badge badge-pill badge-danger pointer">X</span>

          </span></div>
        <div><textarea class="form-control semitransp mt-1 mb-1" v-model="device.imagesprocessed[index].foundText" rows="4" :disabled="device.currentlyProcessing"></textarea></div>
        <button class="btn btn-danger mr-2" v-on:click="updatepreviewImage(device.docId, device.school, index)" :disabled="device.imagesprocessed[index].preview"> <span class="material-icons">preview</span> Gebruik als voorvertoning</button>
        <button class="btn btn-danger" v-on:click="deleteThisImage(device.docId, device.school, index)" :disabled="!device.imagesprocessed[index].gs"> <span class="material-icons">delete</span> Verwijder deze afbeelding</button>
      </div>
    </div>
  </div>
  <a class="carousel-control-prev" :href="'#imageCaroussel-'+device.docId" role="button" data-slide="prev">
    <span class="-carousel-control-prev-icon" aria-hidden="true"><span class="material-icons large text-dark">navigate_before</span></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" :href="'#imageCaroussel-'+device.docId" role="button" data-slide="next">
    <span class="-carousel-control-next-icon" aria-hidden="true"><span class="material-icons large text-dark">navigate_next</span></span>
    <span class="sr-only">Next</span>
  </a>
</div> -->

<FileUploader
          :device="device"
          :school="workingSchool"
/>

<!-- CHROMEINFOCARD -->
<div v-if="chromeinfoWeergeven" class="alert alert-primary" role="alert">
  <p><strong>STATUS: </strong> {{device.g.status}}</p><hr>
    <p><strong>LAATST GESYNCT: </strong> {{new Date(device.g.lastSync).toLocaleString()}}</p><hr>
    <p v-if="device.g.lastKnownNetwork"><strong>LAN NETWORK: </strong> {{device.g.lastKnownNetwork[0].ipAddress}}</p><hr>
    <p v-if="device.g.lastKnownNetwork"><strong>WAN NETWORK: </strong> {{device.g.lastKnownNetwork[0].wanIpAddress}}</p><hr>
    <p><strong>ONTVANGT NOG UPDATES? </strong> <span v-if="device.g.ontvangtNogUpdates">Ja</span><span v-else>Nee</span></p><hr>
    <p><strong>OS:</strong> {{device.g.osVersion}}</p><hr>
    <p ><strong>RAM:</strong> {{bytesToSize(device.g.systemRamTotal)}}</p><hr>
    <p><strong> MAC:</strong> {{macAddressFormat(device.g.macAddress)}}</p><hr>
    <p><strong>DEVICE ID:</strong> {{device.docId}}</p><hr>
    <div><button class="btn btn-info" v-on:click="showRecentUsers = !showRecentUsers">Toon Recente Gebruikers</button> <div v-if="showRecentUsers">
        <span v-for="userobj in device.g.recentUsers" :key="userobj.email"  class="badge badge-info">{{userobj.email}}</span></div>
        </div><hr>
     <div><button class="btn btn-info" v-on:click="showActiveTimeRanges = !showActiveTimeRanges">Toon laatste activiteit</button> <div v-if="showActiveTimeRanges">
        <span v-for="timerange in device.g.activeTimeRanges" :key="timerange.date"  class="badge badge-info"> {{msToTime(timerange.activeTime)}} op  {{timerange.date}}</span></div>
     </div><hr>
     <div><strong>DISKUSAGE: </strong>{{calculateDiskusage(device.g.diskVolumeReports)}}</div><hr>
     <div><strong>CPU USAGE: </strong><span v-for="report in device.g.cpuStatusReports" :key="report.reportTime"><p v-if="report.cpuUtilizationPercentageInfo">{{report.cpuUtilizationPercentageInfo[0]}}% ({{report.reportTime}})</p></span>  </div><hr>
     <a class="btn btn-info" target="_blank" :href="'https://admin.google.com/ac/chrome/devices/'+device.docId+'?org=00ywo9o24jl3gsi'">Link naar Google Admin</a>
   <!-- <pre>{{device.g}}</pre> -->
</div>
<!-- KAARTJE WEERGEVEN -->
<img loading="lazy" v-if="kaartWeergeven && device.gpsLocation && device.gpsLocation.lat && device.gpsLocation.long" :src="getMapsUrl(device.gpsLocation)" class="d-block w-100" alt="...">

 <input type="hidden" :id="'modal-copy-url-'+device.school+'-'+device.docId" :value="generateURL()">
      </div>

      <div class="modal-footer"> <!-- -->
       <button v-on:click="removeDevice(device.docId, workingSchool)" class="btn btn-danger m-1" disabled><span class="material-icons">delete_forever</span></button>

        <button v-on:click="unblockDevice(device.docId,workingSchool)"  v-if="device.g.status !== 'ACTIVE'" class="btn btn-warning" >Toestel deblokkeren</button>
        <button v-on:click="blockDevice(device.docId,workingSchool)" v-if="device.g.status === 'ACTIVE'"  class="btn btn-warning" >Toestel blokkeren</button>
        <button v-on:click="powerwashDevice(device.docId,workingSchool)" class="btn btn-warning">Toestel powerwashen</button>
        <button v-on:click="removeProfiles(device.docId,workingSchool)" class="btn btn-warning">Profielen verwijderen</button>
        <hr>
              <button class="btn btn-primary" v-on:click="copyUrl()"><span class="material-icons">content_copy</span>Kopieer link</button>
              <button :disabled="!device.gpsLocation" class="btn btn-primary" v-on:click="kaartWeergeven = !kaartWeergeven"><span class="material-icons">place</span> Toon kaart</button>
              <button class="btn btn-primary" v-on:click="chromeinfoWeergeven = !chromeinfoWeergeven"><span class="material-icons">insights</span> Toon ChromeInfo</button>
              <button class="btn btn-info" v-on:click="controleerGarantie(device.inbedrijfstelling , device.maandengarantie)"><span class="material-icons">receipt</span> Garantie</button>
              <button v-on:click="processImages(device.docId,workingSchool)" class="btn btn-info"><span class="material-icons">memory</span>Analyseer</button>
              <button type="button" class="btn btn-secondary" v-on:click="updateRouterPathBackToSchool(device.school)" data-dismiss="modal"> <span class="material-icons">close</span>Sluiten</button>
              <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="updateDeviceAndClose(device.docId)"><span class="material-icons">save</span> Opslaan en sluiten</button>
              <button type="button" class="btn btn-primary"  v-on:click="updateDevice(device.docId)"><span class="material-icons">save</span> Opslaan</button>

      <hr>

            <button class="btn btn-secondairy" v-on:click="devToolWeergeven = !devToolWeergeven">DevTool weergeven CHROME</button>
            <pre v-if="devToolWeergeven">{{device}}</pre>
      </div>
    </div>
  </div>
</div>
  </div>
</template>
<style scoped>
.modal{padding:25px}
.large{font-size:48px; text-shadow: 0px 0px 8px white;}
.semitransp {    background-color: rgb(255 255 255 / 75%); }
.pointer{cursor:pointer}
.tags{
      color: #212121 !important;
    background-color: rgb(255 255 255 / 75%) !important;
}
.carousel-indicators li{
  background-color: #dc3545
}
.center-cropped{
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    height: 200px;
}
</style>

<script>
// import * as fb from '../firebase'
import { mapGetters } from 'vuex'
import * as fb from '../firebase'
import FileUploader from './FileUploader'
import DeviceColorBar from '@/components/DeviceColorBar.vue'
import DeviceIcons from '@/components/DeviceIcons.vue'
export default {
  name: 'DeviceModal',
  props: {
    msg: String,
    index: Number,
    device: Object,
    activeModalId: String,
    school: String
  },
  components: {
    FileUploader,
    DeviceColorBar,
    DeviceIcons
  },
  data () {
    return {
      kaartWeergeven: false,
      devToolWeergeven: false,
      chromeinfoWeergeven: false,
      showRecentUsers: false,
      showActiveTimeRanges: false
    }
  },
  watch: {
    'device.uitgeleend':
      function (val, oldVal) {
        if (!val && oldVal) {
          this.device.g.notes = 'Teruggekregen van ' + this.device.uitgeleendaan + ' op ' + new Date().toLocaleDateString() + '\n' + this.device.g.notes
          this.device.uitgeleendaan = ''
        }
      },
    'device.defect':
    function (val, oldVal) {
      if (!val && oldVal) {
        this.device.g.notes = 'Hersteld op ' + new Date().toLocaleDateString() + '\n --->' + this.device.defectopmerking + '\n' + this.device.g.notes
        this.device.defectopmerking = ''
      }
    }

  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user',
      devices: 'devices',
      workingSchool: 'workingSchool',
      toastrOptions: 'toastrOptions',
      toastrOptionsHardToast: 'toastrOptionsHardToast'
    })
  },
  created () {
    if (this.activeModalId && (this.activeModalId === this.device.docId)) {
      console.log('Deze moeten we openen! ' + this.device.docId)

    // document.getElementById('modal-button-' + this.device.school + '-' + this.device.docId).click()
    }
  },
  mounted () {
    if (this.activeModalId && (this.activeModalId === this.device.docId)) {
      console.log('Deze moeten we openen! ' + this.device.docId)
      console.log('faking click on ' + 'modal-button-' + this.device.school + '-' + this.device.docId)
      this.$refs['modal-button-' + this.device.school + '-' + this.device.docId].click()

    // document.getElementById('modal-button-' + this.device.school + '-' + this.device.docId).click()
    }

    this.$refs['modal-' + this.device.school + '-' + this.device.docId].removeEventListener('keydown', this.wantToSave)
    this.$refs['modal-' + this.device.school + '-' + this.device.docId].addEventListener('keydown', this.wantToSave)
  },
  beforeDestroy () {
    this.$refs['modal-' + this.device.school + '-' + this.device.docId].removeEventListener('keydown', this.wantToSave)
  },
  methods: {
    getMapsUrl: function (gpsLocation) {
      var url = 'https://maps.googleapis.com/maps/api/staticmap?center=' + gpsLocation.lat + ',' + gpsLocation.long + '&' +
                  'zoom=18&' +
                  'size=500x400&' +
                  'scale=2&' +
                  'maptype=roadmap&' +
                  'markers=color:red|' + gpsLocation.lat + ',' + gpsLocation.long + '&' +
                  'key=AIzaSyC4oMBZ8aiQzgMXKLIPDZmIQ-HRGuAiQ1o'
      return url
    },
    updateDeviceAndClose: async function (docId) {
      // EERST ALLE device.g-items mappen aan de gewone device dingen, zodat deze ook gevonden worden. zie: TEMP keys. het 's-property' moet weg. enkel g-prop mag nog overblijven in combinatie met de zaken die ook in de andere devices staan.
      console.log('Updating document...')
      this.$toastr.info('Bezig met opslaan...', 'Even geduld...', this.toastrOptions)
      this.device.notes = this.device.g.notes || null
      this.device.locatie = this.device.g.annotatedLocation || null
      this.device.sgassetid = this.device.g.annotatedAssetId || null
      var caseFoldedDeviceDoc = await this.$store.dispatch('caseFoldDoc', this.device)

      var updateChromeDevice = fb.functions.httpsCallable('updateChromeDevice')
      try {
        var result = await updateChromeDevice({ school: this.school, docId: docId, device: caseFoldedDeviceDoc })
        console.log('---------SERVER RESP---------------')
        console.log(result)
        this.$toastr.success('Updating device met ID: <br>' + docId, 'Goed, zo!', this.toastrOptions)
        // Enkele mappings doen, zodat de zoekfuncties in firebase werken zoals de andere toestellen:
      } catch (err) {
        console.log('---------SERVER ERR---------------')
        console.log(err)
        this.$toastr.clear()

        this.$toastr.error(err, 'Fout!', this.toastrOptions)
        return false
      }
      /*  try {
        await fb.db.collection(this.workingSchool).doc(docId).update(caseFoldedDeviceDoc, { merge: true })
        this.$toastr.clear()

        this.$toastr.success('Updating device met ID: <br>' + docId, 'Goed, zo!', this.toastrOptions)
        if (this.device.images) {
          await fb.db.collection(this.workingSchool).doc(docId).update({ images: fb.firestoreFieldvalue.delete() })
        }
      } catch (err) {
        console.log('---------FB ERR---------------')
        console.log(err)
        this.$toastr.clear()

        this.$toastr.error(err, 'Fout!', this.toastrOptions)
        return false
      } */
      this.updateRouterPathBackToSchool(this.workingSchool)

      return false
    },
    updateDevice: async function (docId) {
      // EERST ALLE device.g-items mappen aan de gewone device dingen, zodat deze ook gevonden worden. zie: TEMP keys. het 's-property' moet weg. enkel g-prop mag nog overblijven in combinatie met de zaken die ook in de andere devices staan.
      console.log('Updating document...')
      this.$toastr.info('Bezig met opslaan...', 'Even geduld...', this.toastrOptions)
      this.device.notes = this.device.g.notes || null
      this.device.locatie = this.device.g.annotatedLocation || null
      this.device.sgassetid = this.device.g.annotatedAssetId || null
      var caseFoldedDeviceDoc = await this.$store.dispatch('caseFoldDoc', this.device)

      var updateChromeDevice = fb.functions.httpsCallable('updateChromeDevice')
      try {
        var result = await updateChromeDevice({ school: this.school, docId: docId, device: caseFoldedDeviceDoc })
        console.log('---------SERVER RESP---------------')
        console.log(result)
        this.$toastr.success('Updating device met ID: <br>' + docId, 'Goed, zo!', this.toastrOptions)
      } catch (err) {
        console.log('---------SERVER ERR---------------')
        console.log(err)
        this.$toastr.clear()

        this.$toastr.error(err, 'Fout!', this.toastrOptions)
        return false
      }
      /* try {
        await fb.db.collection(this.workingSchool).doc(docId).update(caseFoldedDeviceDoc, { merge: true })
        this.$toastr.clear()

        this.$toastr.success('Updating device met ID: <br>' + docId, 'Goed, zo!', this.toastrOptions)
        if (this.device.images) {
          await fb.db.collection(this.workingSchool).doc(docId).update({ images: fb.firestoreFieldvalue.delete() })
        }
      } catch (err) {
        console.log('---------FB ERR---------------')
        console.log(err)
        this.$toastr.clear()

        this.$toastr.error(err, 'Fout!', this.toastrOptions)
        return false
      } */
    },
    removeTagFromImage: function (imageindex, tagindex) {
      console.log('removing %s from image %s...', tagindex, imageindex)
      this.device.imagesprocessed[imageindex].tags.splice(tagindex, 1)
    },
    processImages: async function (docId, school) {
      var caseFoldedDeviceDoc = await this.$store.dispatch('caseFoldDoc', this.device)
      caseFoldedDeviceDoc.currentlyProcessing = true
      await fb.db.collection(this.workingSchool).doc(docId).update(caseFoldedDeviceDoc, { merge: true })

      this.$toastr.info('Bezig met analyseren...', 'Even geduld...', this.toastrOptions)

      console.log('Calling process images ', docId, school)
      var processImages = fb.functions.httpsCallable('processImages')
      try {
        var result = await processImages({ school: school, docId: docId, devicetype: this.device.devicetype })
        this.$toastr.info(result.data.result, 'Klaar!', this.toastrOptions)
        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
      }
    },
    controleerGarantie: async function (inbedrijfstelling, maandengarantie) {
      var nogInGarantie = await this.$store.dispatch('controleerGarantie', { inbedrijfstelling: inbedrijfstelling, maandengarantie: maandengarantie })
      console.log('Nog in garantie: %s', nogInGarantie)
      if (nogInGarantie === 'insufficient_data') {
        this.$toastr.warning('Onvoldoende gegevens om dit te berekenen...', 'Hmm...', this.toastrOptions)
        return false
      }
      if (nogInGarantie) {
        this.$toastr.success('Nog in garantie!', 'Goed nieuws', this.toastrOptions)
      } else {
        this.$toastr.warning('Spijtig... Geen garantie meer', 'Jammer', this.toastrOptions)
      }
      return true
    },
    deleteThisImage: async function (docId, school, index) {
      console.log('deleting this image...', docId, school, index)
      var obj = { docId: docId, school: school, imageIndex: index }
      this.$store.dispatch('removeImageFromDocument', obj)
    },
    updatepreviewImage: async function (docId, school, imageindex) {
      console.log('school: %s, docId: %s', school, docId)
      console.log('New preview image is number: %s', imageindex)
      this.device.imagesprocessed.forEach((image, index) => {
        if (index === imageindex) {
          console.log('New previewer!')
          this.device.imagesprocessed[index].preview = true
        } else {
          this.device.imagesprocessed[index].preview = false
        }
      })
      await fb.db.collection(this.workingSchool).doc(docId).update(this.device, { merge: true })
    },

    generateURL () {
      var fullUrl = window.location.origin + this.$route.path + '?school=' + this.device.school + '&deviceId=' + this.device.docId

      return fullUrl
    },
    copyUrl () {
      const testingCodeToCopy = document.querySelector('#modal-copy-url-' + this.device.school + '-' + this.device.docId)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        document.execCommand('copy')

        this.$toastr.success('Link gekopieerd', 'Klaar', this.toastrOptions)
      } catch (err) {
        this.$toastr.warning('Er ging iets fout: ' + err, 'Probleem', this.toastrOptions)
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    bytesToSize (bytes, decimals = 2) {
      if (!bytes || (bytes === '0') || (bytes === 0)) return 'Nog geen gegevens'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    },
    macAddressFormat (mac) {
      if (!mac) { return '' }
      mac = mac.toUpperCase()

      var alphaNum = /^[A-Za-z0-9]+$/

      // The Input will be changed only if the length is 12 and is alphanumeric
      if (mac.length === 12 && alphaNum.test(mac)) {
        // A lot is going on here.
        // .replace(/(.{2})/g,"$1:") - adds ':' every other 2 characters
        // .slice(0,-1) - cuts the last character, because ':' is added
        // .toUpperCase() - because it's good practice for writing MAC Addresses
        mac = mac.replace(/(.{2})/g, '$1:').slice(0, -1).toUpperCase()

        return mac
      }
    },
    msToTime (s) {
      // Pad to 2 or 3 digits, default is 2
      function pad (n, z) {
        z = z || 2
        return ('00' + n).slice(-z)
      }

      var ms = s % 1000
      s = (s - ms) / 1000
      var secs = s % 60
      s = (s - secs) / 60
      var mins = s % 60
      var hrs = (s - mins) / 60
      var returnableString = ''
      if (hrs !== 0) {
        returnableString = returnableString + pad(hrs) + ' uur '
      }
      if (mins !== 0) {
        returnableString = returnableString + pad(mins) + ' minuten '
      }
      if (secs !== 0) {
        returnableString = returnableString + pad(secs) + ' seconden '
      }
      return returnableString
    },
    calculateDiskusage (diskVolumeReports) {
      var totalDisk = 0
      var totalFree = 0
      if (!diskVolumeReports) { return 'Geen gegevens' }
      diskVolumeReports.forEach((report) => {
        var volumeinfo = report.volumeInfo
        volumeinfo.forEach((volinfoobj) => {
          if (volinfoobj.volumeId.indexOf('chronos') > -1) {
            totalDisk = +parseInt(totalDisk) + +parseInt(volinfoobj.storageTotal)
            totalFree = +parseInt(totalFree) + +parseInt(volinfoobj.storageFree)
            console.log('t   ' + totalDisk)
            console.log('f   ' + totalFree)
          }
        })
      })

      var totalUsed = totalDisk - totalFree
      console.log('Total Disk: %s \n Total Free: %s, \n Total Used: %s', totalDisk, totalFree, totalUsed)
      console.log('Total Disk: %s \n Total Free: %s, \n Total Used: %s', this.bytesToSize(totalDisk), this.bytesToSize(totalFree), this.bytesToSize(totalUsed))
      if (isNaN(parseInt(totalUsed / totalDisk * 100))) { return 'Geen gegevens...' }
      return this.bytesToSize(totalUsed) + ' / ' + this.bytesToSize(totalDisk) + ' (' + parseInt(totalUsed / totalDisk * 100) + '% gebruikt)'
    },
    async unblockDevice (docId, school) {
      console.log('unblocking device...')

      var unblockDevice = fb.functions.httpsCallable('unblockDevice')
      try {
        var result = await unblockDevice({ school: school, docId: docId })
        this.$toastr.info(result.data, 'Klaar!', this.toastrOptions)
        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
      }
    },
    async blockDevice (docId, school) {
      console.log('blocking device...')

      var blockDevice = fb.functions.httpsCallable('blockDevice')
      try {
        var result = await blockDevice({ school: school, docId: docId })
        this.$toastr.info(result.data, 'Klaar!', this.toastrOptions)
        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
      }
    },
    async powerwashDevice (docId) {
      console.log('powerwashing device...')
      var powerwashDevice = fb.functions.httpsCallable('powerwashDevice')
      try {
        var result = await powerwashDevice({ docId: docId })
        this.$toastr.info('Order 66 executed.', 'Klaar!', this.toastrOptionsHardToast)
        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
      }
    },
    async removeProfiles (docId) {
      console.log('removeProfiles')
      var wipeUsers = fb.functions.httpsCallable('wipeUsers')
      try {
        var result = await wipeUsers({ docId: docId })
        this.$toastr.info('Order 66 executed.', 'Klaar!', this.toastrOptions)
        console.log(result)
      } catch (err) {
        this.$toastr.error(err, 'Fout!', this.toastrOptions)
        console.log(err)
      }
    },
    removeDevice: function (key, school) {
      var obj = { school: school, docid: key }
      console.log('Removing ', JSON.stringify(obj), school)
      this.$store.dispatch('removeData', obj)
      this.$refs['modal-close-button-' + this.device.school + '-' + this.device.docId].click()
      this.updateRouterPathBackToSchool(school)
    },
    updateRouterPathBackToSchool (school) {
      this.$store.dispatch('setActiveModalId', false)
      this.$router.push('/toestellen?school=' + school)
    },
    updateRouterPathToCurrentDevice (school, docId) {
      try { this.$router.push('/toestellen?school=' + school + '&deviceId=' + docId) } catch (e) { }
    },
    wantToSave (e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return
      }
      e.preventDefault()
      console.log('saving from shortcut!')
      this.updateDevice(this.device.docId)
    }
  }

}
</script>
