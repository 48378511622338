<template>
  <div class="iconholder">
      <span class="child child-1 " v-if="device.defect"><span class="material-icons text-danger ">sentiment_very_dissatisfied</span></span>
      <span class="child child-2 " v-if="device.uitgeleend"><span class="material-icons text-info">family_restroom</span></span>
      <span class="child child-3 " v-if="device.ontvangtNogUpdates === 'nee'"><span class="material-icons text-info">elderly</span></span>
      <span class="child child-4 " v-if="device.g && (device.g.status === 'DISABLED')"><span class="material-icons text-warning">lock</span></span>
      <span class="child child-5 " v-if="device.voorraad"><span class="material-icons">inventory_2</span></span>
      <span class="child child-6 " v-if="device.vastaanmuur"><span class="material-icons text-primary">push_pin</span></span>
      <span class="child child-7 " v-if="device.teverzekeren"><span class="material-icons text-primary">currency_exchange</span></span>
  </div>
</template>

<style scoped>
.iconholder {
    height: 24px;
    width: 120px
}
</style>

<script>
// import * as fb from '../firebase'
import { mapGetters } from 'vuex'
export default {
  name: 'DeviceIcons',
  props: {
    device: Object
  },
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({

    })
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }

}
</script>
