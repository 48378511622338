<template>
    <div class="container border border-warning p-3">
        <h5 class="font-weight-bold">{{ categorie }}</h5>

        <button v-if="linksArrayVAST.length || linksArrayDRAAGBAAR.length" class="btn btn-primary btn-sm" @click=" showLinks = !showLinks">
            <span v-if="!showLinks">Toon</span><span v-else>Verberg</span> Links
        </button>

        <div class="row border-bottom border-dark m-3 p-1">
            <div class="col-2 font-weight-bold d-flex align-content-center justify-content-center flex-wrap">
                <div class="w-100">VAST</div>
                <div>
                    <small class="text-secondary">(blijft op school)</small>
                </div>
            </div>
            <div class="col-2 d-flex align-content-center justify-content-center flex-wrap">
                <code>€ {{ objcontent[categorie]['VAST'] }} </code><br>
                <span class="text-secondary fs-6"><small>Premie:
                        <code>+/- € {{ objcontent[categorie]['PREMIE-' + 'VAST'] }}</code></small> </span>
            </div>
            <div class="col-8 d-flex align-content-center justify-content-center flex-wrap">
                <span v-for="item in objcontent[categorie]['DEVICES' + 'VAST']" :key="item.docId"
                    class="badge bg-secondary m-1">
                    <a :href="'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId"
                        target="_blank" class="text-decoration-none text-white">{{ item.title }} (€ {{ item.prijs }}) </a>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div v-if="showLinks && linksArrayVAST.length > 0" class="border border-secondary card w-100" id="toonLinkjesVAST">
                    <ul class="text-left list-group">
                        <li class="list-group-item">
                            <CopyToClipboard  :buttonTitle="'Kopieer deze lijst'" :contentToCopy="linksArrayVAST.join('\n')"></CopyToClipboard>
                        </li>
                        <li v-for="item in objcontent[categorie]['DEVICES' + 'VAST']" :key="item.docId"
                            class="list-group-item">
                            <a :href="'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId"
                                target="_blank" class="supersmall">{{
                                    'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId
                                }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
<!-- TWEEDE STUK (DRAAGBAAR)-->
        <div class="row m-3 p-1">
            <div class="col-2 font-weight-bold d-flex align-content-center justify-content-center flex-wrap">
                <div class="w-100">DRAAGBAAR</div>
                <div>
                    <small class="text-secondary">(gaat soms mee naar huis)</small>
                </div>
            </div>
            <div class="col-2 d-flex align-content-center justify-content-center flex-wrap">
                <code>€ {{ objcontent[categorie]['DRAAGBAAR'] }} </code><br>
                <span class="text-secondary fs-6"><small>Premie:
                        <code>+/- € {{ objcontent[categorie]['PREMIE-' + 'DRAAGBAAR'] }}</code></small> </span>
            </div>

            <div class="col-8 d-flex align-content-center justify-content-center flex-wrap">

                <span v-for="item in objcontent[categorie]['DEVICES' + 'DRAAGBAAR']" :key="item.docId"
                    class="badge bg-secondary m-1">
                    <a :href="'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId"
                        target="_blank" class="text-decoration-none text-white">{{ item.title }} (€ {{ item.prijs }}) </a>
                </span>

            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div v-if="showLinks  && linksArrayDRAAGBAAR.length > 0" class="card border border-secondary w-100" id="toonLinkjesDRAAGBAAR">
                    <ul class="text-left list-group">
                        <li class="list-group-item">
                            <CopyToClipboard v-if="linksArrayDRAAGBAAR.length > 0" :buttonTitle="'Kopieer deze lijst'" :contentToCopy="linksArrayDRAAGBAAR.join('\n')"></CopyToClipboard>
                        </li>
                        <li v-for="item in objcontent[categorie]['DEVICES' + 'DRAAGBAAR']" :key="item.id"
                            class="list-group-item">
                            <a :href="'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId"
                                target="_blank" class="supersmall">{{
                                    'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId
                                }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>
.supersmall {
    font-size: 0.7rem;
}
</style>

<script>
// import * as fb from '../firebase'
import { mapGetters } from 'vuex'
import CopyToClipboard from '@/components/CopyToClipboardButton.vue'

export default {
  name: 'InsuranceDetails',
  props: {
    objcontent: Object,
    categorie: String
  },
  components: {
    CopyToClipboard
  },
  data () {
    return {
      showLinks: false
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({

    }),
    linksArrayVAST: function () {
      return this.objcontent[this.categorie]['DEVICES' + 'VAST'].map((item) => {
        return 'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId
      })
    },
    linksArrayDRAAGBAAR: function () {
      return this.objcontent[this.categorie]['DEVICES' + 'DRAAGBAAR'].map((item) => {
        return 'https://inventaris.sgfv.be/toestellen?school=' + item.school + '&deviceId=' + item.docId
      })
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }

}
</script>
