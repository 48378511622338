<template>
  <div class="colorbar border-bottom d-flex flex-row flex-nowrap flex-fill mb-2">
      <div class=" item flex-fill" :class="{'disp text-white border border-danger bg-danger': device.defect }">defect</div>
      <div class=" item flex-fill" :class="{'disp text-white border border-info bg-info': device.uitgeleend }" >uitgeleend</div>
      <div class=" item flex-fill" :class="{'disp text-muted border border-warning bg-warning': device.ontvangtNogUpdates === 'nee' }">end of life</div>
      <div class=" item flex-fill" :class="{'disp text-muted border border-warning bg-warning': device.g && (device.g.status === 'DISABLED')}">geblokkeerd</div>
      <div class=" item flex-fill" :class="{'disp text-white border border-dark bg-dark': device.voorraad }">voorraad</div>
      <div class=" item flex-fill" :class="{'disp text-white border border-primary bg-primary': device.vastaanmuur }">blijft op school</div>
  </div>
</template>

<style scoped>
.colorbar {
    height: 12px;
    width: 100%;

}
.disp{

}
.item {

  height:100%;
  background-color: white;
  font-size: xx-small;
  color: white
}

</style>

<script>
// import * as fb from '../firebase'
import { mapGetters } from 'vuex'
export default {
  name: 'DeviceIcons',
  props: {
    device: Object
  },
  components: {

  },
  data () {
    return {

    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({

    })
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }

}
</script>
