<template>
  <div>
      <!-- INPUT ELEMENT -->
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" :id="'addDeviceImages-FileAddon-'+device.docId"><span class="material-icons">photo_library</span> UPLOAD</span>
          </div>
          <div class="custom-file">
            <input
              type="file"
              accept="image/*"
              multiple="multiple"
              v-on:change="previewMultiImage"
              class="custom-file-input"
              :id="'addDeviceImages-FileInput-'+device.docId"
              :aria-describedby="'addDeviceImages-FileAddon-'+device.docId"
            />
            <label class="custom-file-label" :for="'addDeviceImages-FileInput-'+device.docId"
              >Extra foto's toevoegen
            </label>
          </div>
        </div>

<div v-if="image_list.length > 0">
<!-- PREVIEW CONTAINER -->
        <div
          class="d-flex flex-row flex-wrap justify-content-center border p-2 mt-3"
        >

          <template v-if="preview_list.length">
            <div
              class="card"
              style="width: 33%"
              v-for="(item, index) in preview_list"
              :key="index"
            >
              <img v-bind:src="item" class="card-img-top" alt="..." />
              <div class="card-body">

                <div class="card-text">
                  size:  {{bytesToSize( image_list[index].size) }}

                </div>
              </div>
              <div class="card-footer text-muted">
                          Completion:
                  {{ image_uploadStatus[index] }} %
                  <div class="progress">
                    <div
                      class="progress-bar"
                      v-bind:style="{ width: image_uploadStatus[index] + '%' }"
                      role="progressbar"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div><br>
                <a
                  href="#"
                  class="btn btn-danger"
                  v-on:click="removePhoto(index)"
                  > <span class="material-icons">
delete
</span> Verwijderen</a
                >
              </div>
            </div>
          </template>
        </div>

<!-- LOCATIE PERMISSIE -->
    <div class="form-group row text-dark">
    <div class="col-sm-8 offset-sm-4">
      <div class="form-check text-left">
        <input class="form-check-input" type="checkbox" id="locatiemeenemen" v-model="locatiemeenemen">
        <label class="form-check-label" for="locatiemeenemen">
         <span class="material-icons">
my_location
</span> Huidige locatie meenemen
        </label>
      </div>
    </div>
  </div>

<!-- BUTTONS -->

<button class="btn btn-danger" v-on:click="reset"> <span class="material-icons">
delete
</span>Clear All</button>
<button class="btn btn-success" v-on:click="upload" :disabled="working"> <span class="material-icons">
cloud_upload
</span> Upload</button>
<pre class="text-white">{{status}}</pre>
</div>
  </div>
</template>

<style scoped>

</style>

<script>
// import * as fb from '../firebase'
import { mapGetters } from 'vuex'
import * as fb from '../firebase'

export default {
  name: 'DeviceModal',
  props: {
    device: Object,
    school: String
  },
  components: {

  },
  data () {
    return {
      image: null,
      preview_list: [],
      image_list: [],
      image_uploadStatus: [],
      image_preview: [],
      locatiemeenemen: true,
      status: '',
      working: false,
      uploadcomplete: false,
      interval: null
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user',
      devices: 'devices',
      workingSchool: 'workingSchool',
      toastrOptions: 'toastrOptions',
      toastrOptionsHardToast: 'toastrOptionsHardToast'
    })
  },
  created () {

  },

  mounted () {

  },

  methods: {
    forceUpdate () {
      // ...
      this.$forceUpdate() // Notice we have to use a $ here
      // ...
    },
    previewMultiImage: function (event) {
      /* this.preview_list = []
      this.image_list = []
      this.image_uploadStatus = []
      this.image_preview = 0 */
      var input = event.target
      var count = input.files.length
      var index = 0
      if (input.files) {
        while (count--) {
          var reader = new FileReader()
          reader.onload = (e) => {
            this.preview_list.push(e.target.result)
          }
          this.image_list.push(input.files[index])
          this.image_uploadStatus[index] = 0
          reader.readAsDataURL(input.files[index])
          index++
        }
        console.log(this.image_preview)
      }
    },
    updatePreviewImage: function (index) {
      this.image_preview = index
      console.log('Updated preview image: %s', index)
      console.log(this.image_preview)
    },
    reset: function () {
      this.image = null
      this.image_list = []
      this.preview_list = []
      this.image_uploadStatus = []
      this.image_preview = []
      this.school = ''
    },
    removePhoto: function (index) {
      this.image_list.splice(index, 1)
      this.preview_list.splice(index, 1)
      this.image_uploadStatus.splice(index, 1)
      this.image_preview.splice(index, 1)
    },
    getLongAndLat: async function () {
      return await new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      )
    },
    getLocation: async function () {
      try {
        var position = await this.getLongAndLat()
        this.$store.dispatch('setCurrentLocation', position)
        return position
      } catch (e) {
        alert('Error: ' + e.message)
      }
    },
    upload: async function () {
      console.log('Lengte: ' + this.image_list.length)
      if (!this.workingSchool) {
        this.$toastr.error(
          'Kies eerst een school uit de lijst!',
          'OPGEPAST',
          this.toastrOptions
        )
        return false
      }
      if (this.image_list.length === 0) {
        this.$toastr.error(
          'Kies eerst minstens één foto.',
          'OPGEPAST',
          this.toastrOptions
        )
        return false
      }
      this.working = true
      var currentlocation = { lat: null, long: null }
      if (this.locatiemeenemen) {
        if (navigator.geolocation) {
          var position = await this.getLocation()
          currentlocation.lat = position.coords.latitude
          currentlocation.long = position.coords.longitude
          fb.db.collection(this.school).doc(this.device.docId).update({ gpsLocation: currentlocation }, { merge: true })
        } else {
          console.log('Not supported...')
        }
      }

      var self = this
      this.interval = setInterval(function () {
        self.checkstate(self.school, self.device.docId)
      }, 100)
      this.image_list.forEach((file, index) => {
        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = fb.storageRef
          .child(this.school + '/' + this.device.docId + '/' + file.name)
          .put(file)

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          fb.fireb.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )

            this.image_uploadStatus[index] = progress
            this.forceUpdate()
            //   console.log('Upload ' + index + ' is ' + this.image_uploadStatus[index] + '% done')
            switch (snapshot.state) {
              case fb.fireb.storage.TaskState.PAUSED: // or 'paused'
                //   console.log('Upload is paused')
                break
              case fb.fireb.storage.TaskState.RUNNING: // or 'running'
                //   console.log('Upload is running')
                break
            }
          },
          (error) => {
            console.log('Error was thrown!')
            this.working = false
            console.log(error)
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break
              case 'storage/canceled':
                // User canceled the upload
                break

                // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL

            //  console.log('Upload ' + index + ' complete... Getting urls...')
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.image_uploadStatus[index] = 100
            })
          }
        )
      })
      console.log(this.image_list)
      console.log(this.image_preview)
    },
    checkstate: function (school, docId) {
      var done = true
      this.image_uploadStatus.forEach((item) => {
        if (item !== 100) {
          done = false
        }
      })
      if (done) {
        clearInterval(this.interval)
        console.log('>>>>> STOPPED THE INTERVAL <<<<<<<<<<<<<')
        this.uploadcomplete = true
        this.working = false
        this.reset()
        this.$toastr.success(
          'Afbeelding toegevoegd.',
          'KLAAR',
          this.toastrOptions
        )

        // // router.push('/toestellen?school=' + school + '&deviceId=' + docId)
      }
      this.status = JSON.stringify(this.image_uploadStatus)
    },
    bytesToSize (bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    }
  }

}
</script>
