<template>
  <div class="home">
    <div v-if="indexes.length === 0" class="card card-body">
       Geen indexes meer te maken... <br><br><span class="material-icons" >self_improvement</span>
      </div>
    <div class="card m-3" v-for="(item, index) in indexes" :key="index">
      <div class="card-header">
        <h5 class="card-title">
          Aanvrager: {{ item.school }} - {{ item.email }}
        </h5>
      </div>
      <div class="card-body">
        <div v-html="item.message"></div>
      </div>
      <div class="card-footer">
        <button
          class="btn btn-danger card-link"
          v-on:click="removeItem(item.id, index)"
        >
          Remove
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapGetters } from 'vuex'
import * as fb from '../firebase'
export default {
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: 'user'
    })
  },
  data () {
    return {
      indexes: []
    }
  },
  created () {
    this.indexes = []
    fb.db
      .collection('indexes_todo').onSnapshot((querySnapshot) => {
        console.log('Looping over indexes')
        querySnapshot.forEach((doc) => {
          var indexTodo = doc.data()
          indexTodo.id = doc.id
          console.log(indexTodo)
          this.indexes.push(indexTodo)
        })
      })
      /* .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          var indexTodo = doc.data()
          indexTodo.id = doc.id
          console.log(indexTodo)
          this.indexes.push(indexTodo)
        })
      })
      */
  },
  methods: {

    async removeItem (docId, index) {
      console.log('removing...')
      await fb.db.collection('indexes_todo').doc(docId).delete()
      this.indexes = []
      fb.db
        .collection('indexes_todo').onSnapshot((querySnapshot) => {
          console.log('Looping over indexes')
          querySnapshot.forEach((doc) => {
            var indexTodo = doc.data()
            indexTodo.id = doc.id
            console.log(indexTodo)
            this.indexes.push(indexTodo)
          })
        })
    }
  }
}
</script>
